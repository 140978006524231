import classNames from "classnames";
import { HTMLProps } from "react";

type Size = "2xs" | "xs" | "sm" | "md" | "lg" | "xl" | "2xl" | "3xl" | "fill";

const SIZE_CLASSES: Record<Size, string> = {
  "2xs": "w-200 h-200",
  xs: "w-300 h-300",
  sm: "w-400 h-400",
  md: "w-600 h-600",
  lg: "w-800 h-800",
  xl: "w-1200 h-1200",
  "2xl": "w-1600 h-1600",
  "3xl": "w-2000 h-2000",
  fill: "w-full h-full",
};

type Fill =
  | "active"
  | "default"
  | "subdued"
  | "disabled"
  | "critical"
  | "info"
  | "success"
  | "warning"
  | "brand"
  | "accent"
  | "white";

const FILL_CLASSES: Record<Fill, string> = {
  accent: "fill-white",
  active: "fill-active",
  default: "fill",
  subdued: "fill-subdued",
  disabled: "fill-disabled",
  critical: "fill-critical",
  info: "fill-info",
  success: "fill-success",
  warning: "fill-warning",
  white: "fill-white",
  brand: "fill-brand",
};

export interface IconProps
  extends React.PropsWithChildren,
    Omit<HTMLProps<HTMLSpanElement>, "size"> {
  size?: Size;
  fill?: Fill;
  alt?: string;
}

export const Icon = ({
  children,
  alt = "",
  size = "md",
  fill = "default",
  className,
}: IconProps) => {
  return (
    <span
      className={classNames(
        SIZE_CLASSES[size],
        FILL_CLASSES[fill],
        "flex aspect-square items-center align-middle [&>*]:h-full [&>*]:w-full",
        className,
      )}
      title={alt}
    >
      {children}
    </span>
  );
};
