import { ClientFactorySingleton } from "@/data/clientFactory";
import useSWR from "swr";
import { useGetToken } from "@/data/useGetToken";

const GET_SALTBOX_LOCATIONS = "GET_SALTBOX_LOCATIONS";
export function useGetSaltboxLocations() {
  const { getToken } = useGetToken();
  const fetcher = async () => {
    try {
      const token = await getToken();
      const client = await ClientFactorySingleton.getClient(token);
      return await client.locationService.getSaltboxLocations();
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  return useSWR(GET_SALTBOX_LOCATIONS, fetcher);
}
