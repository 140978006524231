var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"h6jcxQc644Fu3jxfP7v-y"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

Sentry.init({
  dsn: "https://f0b8414f57d4d5740313a3cbd0c3d89e@o4506904866586624.ingest.us.sentry.io/4506905302138880",

  // It's recommended to have a lower tracesSampleRate in production to avoid performance
  // overhead and excessive data usage. Usually, a rate between 0.1 (10%) and 1 (100%) is
  // used, depending on the traffic and the level of detail you need.
  tracesSampleRate: 0.2,

  debug: process.env.NODE_ENV !== 'production',

  // You should adjust the value of replaysOnErrorSampleRate to determine the percentage
  // of error events that will generate a replay. In production, you might not want to
  // capture every error, especially if they're frequent. A value between 0.1 (10%) to
  // 0.3 (30%) can be a good start, and you can adjust based on the volume of errors.
  replaysOnErrorSampleRate: 0.3,

  // For replaysSessionSampleRate, which controls the overall percentage of sessions
  // that will be captured with replays, a small percentage is typically sufficient
  // for getting a good picture of user issues, such as 0.05 (5%). This is because
  // session replays can be heavy on storage and privacy-sensitive.
  replaysSessionSampleRate: 0.05,

  integrations: [
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
});
