import React, {
  ReactNode,
  RefObject,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { createPortal } from "react-dom";

interface StickyFooterPortalContextState {
  containerRef: RefObject<HTMLDivElement> | null;
}

const StickyFooterPortalContext = createContext<StickyFooterPortalContextState>(
  {
    containerRef: null,
  },
);

export const StickyFooterPortalContextProvider = ({
  children,
}: React.PropsWithChildren) => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  return (
    <StickyFooterPortalContext.Provider value={{ containerRef }}>
      <div
        id="StickyFooter_portal"
        ref={containerRef}
        className="min-h-[calc(100svh-64px)]"
      >
        {children}
      </div>
    </StickyFooterPortalContext.Provider>
  );
};

export const useStickyFooterPortalContext = () => {
  return useContext(StickyFooterPortalContext);
};

export const useCreateStickyFooter = () => {
  const { containerRef } = useStickyFooterPortalContext();
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const createStickyFooter = (children: ReactNode) => {
    if (isMounted && containerRef?.current) {
      return createPortal(children, containerRef.current);
    } else if (isMounted) {
      setTimeout(() => createStickyFooter(children), 100);
      return null;
    }

    return null;
  };

  return { createStickyFooter };
};
