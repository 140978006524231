const shopifyHelpLinks = {
  countryOfOrigin:
    "https://help.shopify.com/en/manual/products/details#product-details-shipping",
  harmonizedSystemCode:
    "https://help.shopify.com/en/manual/shipping/shopify-shipping/hs-codes",
  addPrice:
    "https://help.shopify.com/en/manual/products/details/product-pricing/unit-pricing#add-unit-prices-to-your-product",
  addWeight:
    "https://help.shopify.com/en/manual/products/details#product-details-shipping",
  incoterms:
    "https://help.shopify.com/en/manual/international/duties-and-import-taxes#incoterms",
};

export const externalLinks = {
  howItWorks:
    "https://www.saltbox.com/how-it-works?source=oregano%20onboarding",
  saltboxHomepage: "https://www.saltbox.com/?source=parsel%20nav",
  ...shopifyHelpLinks,
};
