import { ShippingAddress } from "@/apicodegen/api";
import { ClientFactorySingleton } from "@/data/clientFactory";
import { useRouter } from "next/router";
import { useCallback, useState } from "react";
import { v4 } from "uuid";
import { useGetShippingOrigins } from "./useGetShippingOrigins";
import { useGetOrganization } from "../api/organization/useGetOrganization";
import { useGetToken } from "@/data/useGetToken";
import useSWRMutation from "swr/mutation";

interface SetShippingOriginRequest {
  address: ShippingAddress;
  pickupInstructions: string;
}

interface UseSetShippingOriginsProps {
  onSuccess?: () => void;
  onError?: () => void;
}

export function useSetShippingOrigins({
  onSuccess,
  onError,
}: UseSetShippingOriginsProps) {
  const { mutate: mutateShippingOrigins } = useGetShippingOrigins();
  const { mutate: mutateSaltboxLocation } = useGetOrganization();
  const { getToken } = useGetToken();

  const mutator = async (
    _: any,
    { arg: origin }: { arg: SetShippingOriginRequest },
  ) => {
    const token = await getToken();
    const client = await ClientFactorySingleton.getClient(token);
    try {
      const newOrigins = [];

      if (origin != null) {
        const newOrigin = {
          id: v4(),
          address: origin.address,
          name: "Home",
          pickupInstructions: origin.pickupInstructions,
        };
        newOrigins.push(newOrigin);
      }

      await client.locationService.setShippingOrigins({
        setShippingOriginsRequestBody: newOrigins,
      });

      await mutateShippingOrigins();
      await mutateSaltboxLocation();
    } catch (e) {
      console.log(e);
      throw e;
    }
  };

  return useSWRMutation("SET_SHIPPING_ORIGINS", mutator, {
    onSuccess,
    onError,
  });
}
