import useSWR from "swr";
import { useGetToken } from "@/data/useGetToken";
import { ClientFactorySingleton } from "@/data/clientFactory";

export const GET_ORGANIZATIONS = "getOrganizations";

export const useGetOrganizations = () => {
  const { getToken } = useGetToken();
  const fetcher = async () => {
    const token = await getToken();
    const client = await ClientFactorySingleton.getClient(token);
    return await client.organizationService.getOrganizationsSkinny();
  };

  return useSWR(GET_ORGANIZATIONS, fetcher);
};
