import { ShippingAddress } from "@/apicodegen/api";
import { OreganoText, OreganoTextField } from "@/components/foundation";
import { useForm } from "react-hook-form";
import { useOrganizationContext } from "@/context/OrganizationContext";
import { HookFormTextField } from "@/components/foundation/HookFormTextField/HookFormTextField";
import { NoSsrAddressOneSuggestionHookFormTextField } from "../../NoSsrAddressOneSuggestionHookFormTextField";

export interface ShipFromOwnAddressFormData {
  address1: string;
  address2: string | undefined;
  city: string;
  state: string;
  zip: string;
  instructions: string | undefined;
}

export interface ShipFromOwnAddressFormProps {
  formId: string;
  onSubmit: (data: ShipFromOwnAddressFormData) => void;
}

export const ShipFromOwnAddressForm = ({
  formId,
  onSubmit,
}: ShipFromOwnAddressFormProps) => {
  const { shippingOrigin } = useOrganizationContext();

  const {
    watch,
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<ShipFromOwnAddressFormData>({
    defaultValues:
      shippingOrigin != null
        ? {
            address1: shippingOrigin.address.address1,
            address2: shippingOrigin.address.address2,
            city: shippingOrigin.address.city,
            state: shippingOrigin.address.state,
            zip: shippingOrigin.address.zip,
            instructions: shippingOrigin.pickupInstructions,
          }
        : undefined,
    reValidateMode: "onBlur",
  });

  return (
    <form
      id={formId}
      className="flex flex-col gap-400"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="grid grid-cols-1 gap-x-200 gap-y-400 md:grid-cols-2">
        <label className="flex flex-1 flex-col gap-100">
          <OreganoText>Address 1</OreganoText>
          <NoSsrAddressOneSuggestionHookFormTextField
            control={control}
            value={watch("address1")}
            placeholder="Address"
            nearAtlanta
            {...register("address1", { required: true })}
            onChange={(addressSuggestion: Partial<ShippingAddress>) => {
              setValue("address1", addressSuggestion.address1 || "");
              if (addressSuggestion.address2 != null) {
                setValue("address2", addressSuggestion.address2);
              }
              if (addressSuggestion.city != null) {
                setValue("city", addressSuggestion.city);
              }
              if (addressSuggestion.state != null) {
                setValue("state", addressSuggestion.state);
              }
              if (addressSuggestion.zip != null) {
                setValue("zip", addressSuggestion.zip);
              }
            }}
            hasError={errors.address1 != null}
          />
        </label>
        <label className="flex flex-1 flex-col gap-100">
          <OreganoText>Address 2 (optional)</OreganoText>
          <HookFormTextField
            control={control}
            {...register("address2")}
            placeholder="Apartment, suite, etc."
            variant="secondary"
          />
        </label>
        <label className="flex flex-1 flex-col gap-100">
          <OreganoText>City</OreganoText>
          <HookFormTextField
            control={control}
            {...register("city", { required: true })}
            placeholder="City"
            variant="secondary"
          />
        </label>
        <label className="flex flex-1 flex-col gap-100">
          <OreganoText>State</OreganoText>
          <HookFormTextField
            control={control}
            {...register("state", { required: true })}
            placeholder="State"
            variant="secondary"
          />
        </label>
        <label className="flex flex-1 flex-col gap-100">
          <OreganoText>ZIP code</OreganoText>
          <HookFormTextField
            control={control}
            {...register("zip", { required: true })}
            placeholder="Zip"
            variant="secondary"
          />
        </label>

        <label className="col-span-1 flex flex-1 flex-col gap-100 md:col-span-2">
          <OreganoText>Pick up instructions (optional)</OreganoText>
          <textarea
            {...register("instructions")}
            spellCheck
            className="placeholder-subdued h-full w-full resize-none rounded border bg-white py-200 pl-400 text-100 font-normal leading-100 focus:bg-input-focus"
            rows={3}
            placeholder="Pickup at front door, buzzer code 1234"
          />
        </label>
      </div>
    </form>
  );
};
