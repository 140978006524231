import classNames from "classnames";

type Size = "default" | "small";

const SIZE_CLASSES: Record<Size, string> = {
  default: "w-500 h-500",
  small: "w-400 h-400",
};

interface LoadingSpinnerProps {
  size?: Size;
}

export const LoadingSpinner = ({ size = "default" }: LoadingSpinnerProps) => (
  <div
    className={classNames(
      "m-auto inline-block animate-spin rounded-full border-2 border-solid border-corrugated-600 border-t-transparent text",
      SIZE_CLASSES[size],
    )}
    role="status"
  >
    <span className="hidden">Loading...</span>
  </div>
);
