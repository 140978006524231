import { HTMLProps } from "react";
import classNames from "classnames";
import { SpinnerThirdIcon } from "@/public/icons/solid";
import { Icon } from "./Icon";

export type VariantType =
  | "default"
  | "onSurface"
  | "secondary"
  | "brand"
  | "outline"
  | "plain"
  | "critical"
  | "criticalPlain"
  | "criticalText"
  | "icon"
  | "none";

const VARIANT_STYLES: Record<VariantType, string> = {
  default:
    "text-light bg-button border-transparent disabled:bg-button-disabled",
  onSurface:
    "bg-button-onSurface border-transparent disabled:bg-button-onSurface-disabled",
  secondary:
    "bg-button-secondary border-transparent disabled:bg-button-secondary-disabled disabled:text-light-subdued",
  brand: "bg-button-brand border-transparent disabled:bg-button-brand-disabled",
  outline: "text border-dark disabled:bg-button-disabled",
  plain: "text border-transparent disabled:text-disabled",
  critical: "text-critical border-critical disabled:bg-red-300",
  criticalPlain: "text-critical border-transparent disabled:text-disabled",
  criticalText: "text-critical border-transparent disabled:text-red-300",
  icon: "text border-transparent disabled:text-disabled",
  none: "text border-transparent disabled:text-disabled",
};

// we don't want active buttons to have a hoverable state
const NON_ACTIVE_VARIANT_STYLES: Record<VariantType, string> = {
  default:
    "enabled:hover:bg-button-hover enabled:hover:text enabled:hover:border-dark",
  onSurface: "enabled:hover:bg-button-onSurface-hover",
  secondary: "enabled:hover:bg-button-secondary-hover",
  brand: "enabled:hover:bg-button-brand-hover",
  outline:
    "enabled:hover:bg-button-onSurface enabled:hover:text enabled:hover:border-dark",
  plain: "enabled:hover:bg-card-hover",
  critical:
    "enabled:hover:bg-card-critical-hover enabled:hover:text-critical-onSurface",
  criticalPlain: "enabled:hover:bg-button-critical-hover",
  criticalText:
    "enabled:hover:bg-critical-hover enabled:hover:text-critical-onSurface",
  icon: "enabled:hover:bg-card-hover",
  none: "",
};

const ACTIVE_VARIANT_STYLES: Record<VariantType, string> = {
  default: "",
  onSurface: "e",
  secondary: "",
  brand: "",
  outline: "bg-button-secondary-hover",
  plain: "",
  critical: "",
  criticalPlain: "",
  criticalText: "",
  icon: "",
  none: "",
};

export type ShapeType =
  | "default"
  | "small"
  | "square"
  | "squareSmall"
  | "pill"
  | "pillLarge"
  | "none";

const SHAPE_STYLES: Record<ShapeType, string> = {
  default: "py-200 px-400 rounded font-semibold",
  small: "px-400 py-100 rounded font-semibold",
  square: "p-400 h-auto rounded w-auto aspect-square",
  squareSmall: "p-100 rounded-sm aspect-square",
  pill: "px-400 py-100 font-medium rounded-full",
  pillLarge: "px-400 py-200 font-medium rounded-full",
  none: "",
};

type AlignType = "left" | "center" | "right";

const ALIGN_STYLES: Record<AlignType, string> = {
  left: "justify-left",
  center: "justify-center",
  right: "justify-right",
};

const BUTTON_BASE_STYLES =
  "border duration-200 relative disabled:cursor-not-allowed text-200 leading-200";

export interface ButtonProps extends HTMLProps<HTMLButtonElement> {
  active?: boolean;
  align?: AlignType;
  loading?: boolean;
  shape?: ShapeType;
  type?: "button" | "submit" | "reset";
  variant?: VariantType;
}

export const Button = ({
  active = false,
  align = "center",
  children,
  className,
  disabled,
  loading = false,
  shape = "default",
  type = "button",
  variant = "default",
  ...props
}: ButtonProps) => {
  return (
    <button
      {...props}
      type={type}
      className={classNames(
        VARIANT_STYLES[variant],
        SHAPE_STYLES[shape],
        BUTTON_BASE_STYLES,
        active
          ? ACTIVE_VARIANT_STYLES[variant]
          : NON_ACTIVE_VARIANT_STYLES[variant],
        className,
      )}
      disabled={loading || disabled}
    >
      <div
        className={classNames(
          "flex h-full w-full flex-row items-center",
          ALIGN_STYLES[align],
          loading && "opacity-0",
        )}
      >
        {children}
      </div>
      {loading && (
        <div
          className={classNames(
            "absolute left-0 top-0 flex h-full w-full items-center justify-center py-200",
            SHAPE_STYLES[shape],
          )}
        >
          <Icon size="fill">
            <SpinnerThirdIcon className={loading ? "animate-spin" : ""} />
          </Icon>
        </div>
      )}
    </button>
  );
};
