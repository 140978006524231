import classNames from "classnames";
import { LoadingSpinner } from "../LoadingSpinner";

interface FullLoadingPageProps {
  adjustingForTopBar?: boolean;
}

export function FullLoadingPage({
  adjustingForTopBar = true,
}: FullLoadingPageProps) {
  return (
    <div
      className={classNames(
        "flex w-full justify-center align-middle",
        adjustingForTopBar ? "h-[calc(100svh-64px)]" : "h-full",
      )}
    >
      <LoadingSpinner />
    </div>
  );
}
