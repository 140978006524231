import { Fragment, HTMLProps, ReactNode } from "react";
import classNames from "classnames";
import { Text, type TextVariant } from "../Text";

type Variant =
  | "accent"
  | "critical"
  | "dark"
  | "default"
  | "outlined"
  | "subdued"
  | "success"
  | "warning";

const VARIANT_CLASSES: Record<Variant, string> = {
  accent: "bg-tag-accent text-white",
  critical: "bg-tag-critical text-critical-500",
  dark: "bg-dark text-light",
  default: "bg-tag text",
  outlined: "bg-tag-outlined border border-dark",
  subdued: "bg-tag-subdued",
  success: "bg-tag-success text-success",
  warning: "bg-tag-warning text",
};

type Padding = "none" | "small" | "default";

const PADDING_CLASSES: Record<Padding, string> = {
  none: "",
  small: "px-100 py-50",
  default: "px-200 py-100",
};

interface TagProps extends HTMLProps<HTMLDivElement> {
  variant?: Variant;
  textVariant?: TextVariant;
  padding?: Padding;
}

export const Tag = ({
  children,
  className,
  variant = "default",
  textVariant = "headingSm",
  padding = "default",
  ...props
}: TagProps) => {
  return (
    <div
      className={classNames(
        "inline-block h-auto w-auto shrink-0 grow-0 self-start justify-self-start rounded-sm",
        PADDING_CLASSES[padding],
        VARIANT_CLASSES[variant],
        className,
      )}
      {...props}
    >
      {typeof children === "string" ? (
        <Text
          variant={textVariant}
          color={
            variant === "accent" || variant === "dark" ? "light" : "default"
          }
        >
          {children}
        </Text>
      ) : (
        <Fragment>{children}</Fragment>
      )}
    </div>
  );
};
