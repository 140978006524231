import classNames from "classnames";

export interface HideWhenScreenIsProps extends React.PropsWithChildren {
  as?: keyof JSX.IntrinsicElements;
  smallest?: boolean;
  xs?: boolean;
  sm?: boolean;
  md?: boolean;
  lg?: boolean;
  className?: string;
}

export const HideWhenScreenIs = ({
  as: Component = "div",
  smallest = false,
  xs = false,
  sm = false,
  md = false,
  lg = false,
  className,
  children,
}: HideWhenScreenIsProps) => {
  return (
    <Component
      className={classNames(
        smallest && "max-xs:hidden",
        xs && "xs:max-sm:hidden",
        sm && "sm:max-md:hidden",
        md && "md:max-lg:hidden",
        lg && "lg:hidden",
        className,
      )}
    >
      {children}
    </Component>
  );
};
