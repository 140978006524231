import { useUser } from "@clerk/nextjs";
import Script from "next/script";
import { useEffect, useRef } from "react";

export default function MouseflowSnippet() {
  useTagMouseflowSignedUpUser();
  return <Script src="/mouseflow.js" />;
}

export function useTagMouseflowSignedUpUser() {
  const { user } = useUser();
  const taggedMouseflow = useRef(false);

  useEffect(() => {
    if (
      !taggedMouseflow.current &&
      user?.primaryEmailAddress?.emailAddress != null
    ) {
      /* @ts-ignore */
      window._mfq = window._mfq || [];

      /* @ts-ignore */
      window._mfq.push(["setVariable", "SignedUpUser", "Yes"]);

      /* @ts-ignore */
      window._mfq.push([
        "setVariable",
        "Email",
        user.primaryEmailAddress?.emailAddress,
      ]);

      taggedMouseflow.current = true;
    }
  }, [user]);
}
