import { useScreenSize } from "@/hooks/useScreenSize";
import { useBodyScrollable } from "@/hooks/utils/useBodyScrollable";
import { rawRoutes } from "@/routes";
import { useRouter } from "next/router";
import { createContext, useContext, useState } from "react";

interface LayoutState {
  isRawPage: boolean;
  hideSidebar: boolean;
  isScrollable: boolean;
  scrollbarWidth: number;
  isMediumScreen: boolean;
  // TODO (kasparisso): Remove this overlap
  sidebarVisible: boolean;
  preventUserScroll: boolean;
  setPreventUserScroll: (arg: boolean) => void;
}

const LayoutContext = createContext<LayoutState>({} as any);

export interface LayoutProviderProps {
  children: React.ReactNode;
}

export const LayoutProvider = ({ children }: LayoutProviderProps) => {
  const router = useRouter();
  const { isScrollable, scrollbarWidth } = useBodyScrollable();
  const isRawPage =
    Object.values(rawRoutes).find((r) => router.pathname.startsWith(r)) != null;
  const hideSidebar = router.pathname.startsWith("/onboarding") || isRawPage;
  const { isMediumScreen } = useScreenSize();
  const [preventUserScroll, setPreventUserScroll] = useState(false);

  return (
    <LayoutContext.Provider
      value={{
        isRawPage,
        hideSidebar,
        isScrollable,
        scrollbarWidth,
        isMediumScreen,
        sidebarVisible: !hideSidebar,
        preventUserScroll,
        setPreventUserScroll,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};

export const useLayoutContext = () => useContext(LayoutContext);
