import { ClientFactorySingleton } from "@/data/clientFactory";
import { useGetToken } from "@/data/useGetToken";
import useSWRMutation from "swr/mutation";

const INITIATE_PORTAL_SESSION = "INITIATE_PORTAL_SESSION";
export function useInitiateCustomerPortalSession() {
  const { getToken } = useGetToken();
  const trigger = async () => {
    const token = await getToken();
    const client = await ClientFactorySingleton.getClient(token);
    try {
      const sessionUrl =
        await client.billingService.initiateCustomerPortalSession({
          returnUrl: window.location.href,
        });
      return sessionUrl;
    } catch (e) {
      console.log(e);
      throw e;
    }
  };

  return useSWRMutation(INITIATE_PORTAL_SESSION, trigger);
}
