import { ReactNode } from "react";
import { routes } from "@/routes";
import { useRouter } from "next/router";
import Link from "next/link";
import { Icon } from "../../Icon";
import { Text } from "../../Text";

import {
  TagsIcon,
  GearIcon,
  DollarSignIcon,
  TruckIcon,
  BoltIcon,
  BoxIcon,
  CommentIcon,
  ShopIcon,
  BarcodeScanIcon,
} from "@/public/icons/solid";
import classNames from "classnames";
import { useBillingPortal } from "@/hooks/useBillingPortal";
import { HideWhenScreenIs } from "../../HideWhenScreenIs";
import { useOrganizationContext } from "@/context/OrganizationContext";
import { show as showIntercomChat } from "@intercom/messenger-js-sdk";

const BillingNavButton = () => {
  const { redirectToBillingPortal } = useBillingPortal();

  return (
    <div
      className={classNames(
        "group/navItem relative flex h-1000 w-auto select-none flex-row items-center rounded transition hover:cursor-pointer hover:bg-card-hover",
      )}
      onClick={redirectToBillingPortal}
    >
      <div className="flex h-1000 w-1000 shrink-0 items-center justify-center">
        <Icon className={classNames("group-hover/navItem:fill-hover")}>
          <DollarSignIcon />
        </Icon>
      </div>
      <Text
        variant={"subheadingSm"}
        className="w-0 opacity-0 transition-opacity group-hover:w-auto group-hover/navbar:opacity-100"
      >
        Billing
      </Text>
    </div>
  );
};

export const SideBarNav = () => {
  const router = useRouter();
  const { isOperator, isWorkspaceMember, pickupBeta } =
    useOrganizationContext();

  return (
    <HideWhenScreenIs smallest xs sm>
      <nav
        className="group/navbar hide-sm fixed left-0 top-1600 z-frame flex h-[calc(100vh-64px)] w-1600 
      max-w-[100vw] flex-col justify-between border-r bg-card p-300 transition-[width] hover:w-6400"
      >
        <div className="flex flex-col gap-300">
          {isOperator && (
            <NavButton
              title="Scan"
              route={routes.scan}
              active={routes.scan === router.pathname}
              icon={<BarcodeScanIcon />}
            />
          )}
          {!isOperator && (
            <>
              {!isWorkspaceMember && pickupBeta && (
                <Link
                  href={routes.home}
                  className="group/navItem duration flex h-1000 w-auto flex-row items-center rounded bg-brand-yellow transition hover:bg-brand-yellowLight"
                >
                  <div className="flex h-1000 w-1000 shrink-0 items-center justify-center">
                    <Icon
                      fill={
                        routes.home === router.pathname ? "active" : "white"
                      }
                      className={classNames(
                        routes.home === router.pathname
                          ? ""
                          : "group-hover/navItem:fill-hover",
                      )}
                    >
                      <BoltIcon />
                    </Icon>
                  </div>
                  <Text
                    variant="headingSm"
                    className="w-0 text-nowrap font-black opacity-0 transition-opacity group-hover:w-auto group-hover/navbar:opacity-100"
                    italic
                    uppercase
                  >
                    Ship
                  </Text>
                </Link>
              )}
              {isWorkspaceMember && (
                <NavButton
                  title="Orders"
                  route={routes.orders}
                  active={routes.orders === router.pathname}
                  icon={<TagsIcon />}
                />
              )}
              {!isWorkspaceMember && pickupBeta && (
                <NavButton
                  title="Pickups"
                  route={routes.pickups}
                  active={routes.pickups === router.pathname}
                  icon={<TruckIcon />}
                />
              )}
              <NavButton
                title="Shipments"
                route={routes.shipments}
                active={routes.shipments === router.pathname}
                icon={<BoxIcon />}
              />
              <BillingNavButton />
            </>
          )}
        </div>
        <div className="flex flex-col gap-300">
          {!isOperator && <ChatButton />}
          <NavButton
            title="Settings"
            route={routes.settings}
            active={router.pathname.includes(routes.settings)}
            icon={<GearIcon />}
          />
        </div>
      </nav>
    </HideWhenScreenIs>
  );
};

interface NavButtonProps {
  title: string;
  route: string;
  active: boolean;
  icon: ReactNode;
}

const NavButton = ({ title, route, active, icon }: NavButtonProps) => (
  <Link
    href={route}
    className={classNames(
      "group/navItem flex h-1000 w-auto flex-row items-center rounded transition hover:bg-card-hover",
      active && "bg-card-selected",
    )}
  >
    <div className="flex h-1000 w-1000 shrink-0 items-center justify-center">
      <Icon
        fill={active ? "active" : "default"}
        className={classNames(active ? "" : "group-hover/navItem:fill-hover")}
      >
        {icon}
      </Icon>
    </div>
    <Text
      variant={active ? "headingSm" : "subheadingSm"}
      className="w-0 text-nowrap opacity-0 transition-opacity group-hover:w-auto group-hover/navbar:opacity-100"
    >
      {title}
    </Text>
  </Link>
);

const ChatButton = () => (
  <div
    className="group/navItem flex h-1000 w-auto flex-row items-center rounded transition hover:cursor-pointer hover:bg-card-hover"
    onClick={() => showIntercomChat()}
  >
    <div className="flex h-1000 w-1000 shrink-0 items-center justify-center">
      <Icon className="group-hover/navItem:fill-hover">
        <CommentIcon />
      </Icon>
    </div>
    <Text
      variant={"subheadingSm"}
      className="w-0 text-nowrap opacity-0 transition-opacity group-hover:w-auto group-hover/navbar:opacity-100"
    >
      Chat with us
    </Text>
  </div>
);
