import useSWR from "swr";
import { useGetToken } from "@/data/useGetToken";
import { ClientFactorySingleton } from "@/data/clientFactory";

export function useGetShopifyShopInfo(shouldFetch = true) {
  const { getToken } = useGetToken();
  const shopInfoFetcher = async () => {
    if (!shouldFetch) return null;
    try {
      const token = await getToken();
      const client = await ClientFactorySingleton.getClient(token);
      const resp = await client.storeConnections.getShopInfo();
      return resp;
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  return useSWR("SHOPIFY_SHOP_INFO", shopInfoFetcher, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    shouldRetryOnError: false,
  });
}
