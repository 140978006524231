import { Text } from "../../Text";

function extractFirstAlphabetOrNumber(name: string): string {
  if (name.toLowerCase() === "default") {
    return "👋";
  }
  // Use a regular expression to find the first occurrence of an alphabet or a number
  const match = name.match(/[A-Za-z0-9]/);
  // If a match is found, return it, otherwise return an empty string
  return match ? match[0].toUpperCase() : "👋";
}

export const Avatar = ({ name }: { name: string }) => {
  const displayAvatar = extractFirstAlphabetOrNumber(name);
  return (
    <div className="flex aspect-square h-5 w-5 shrink-0 items-center justify-center rounded-full bg-dark">
      <Text
        className="text-light"
        variant={displayAvatar === "👋" ? "bodySm" : "subheadingMd"}
      >
        {displayAvatar}
      </Text>
    </div>
  );
};
