import React, {
  ForwardedRef,
  HTMLProps,
  MutableRefObject,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from "react";
import classNames from "classnames";
import { Text } from "../Text";
import PhoneInput from "react-phone-number-input/react-hook-form-input";
import { Control } from "react-hook-form";

type Variant = "primary" | "secondary" | "login";

const VARIANT_CLASSES: Record<Variant, string> = {
  primary: "rounded-full bg-input text-200 md:text-75 font-medium",
  secondary: "rounded bg-white text-200 md:text-100 font-normal",
  login: "rounded bg-white text-200 md:text-100 font-medium",
};

export interface HookFormTextFieldProps extends HTMLProps<HTMLInputElement> {
  control: Control<any, any>;
  label?: string;
  placeholder?: string;
  className?: string;
  adornment?: string | React.ReactNode;
  variant?: Variant;
  hasError?: boolean;
  onSubmit?: () => void;
}

export const HookFormTextField = forwardRef<
  HTMLInputElement,
  HookFormTextFieldProps
>(
  (
    {
      control,
      label,
      placeholder,
      value,
      onChange,
      className = "",
      onSubmit,
      type,
      adornment,
      variant = "primary",
      hasError = false,
      ...props
    },
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const [numericEmptyOverride, setNumericEmptyOverride] = useState(false);

    useEffect(() => {
      // Define the event handler inside useEffect
      const handleWheel = (event: WheelEvent) => {
        const activeElement = document.activeElement;

        if (
          activeElement &&
          activeElement instanceof HTMLElement &&
          activeElement.tagName === "INPUT" &&
          (activeElement as HTMLInputElement).type === "number"
        ) {
          activeElement.blur();
        }
      };

      // Add the event listener
      document.addEventListener("wheel", handleWheel);

      // Return a cleanup function that removes the event listener
      return () => {
        document.removeEventListener("wheel", handleWheel);
      };
    }, []);

    return (
      <div className={classNames("relative", className)}>
        {type === "tel" ? (
          <PhoneInput
            country="US"
            control={control}
            className={classNames(
              "placeholder-subdued h-full w-full border py-200 pl-400 leading-100 focus:bg-input-focus md:text-100",
              VARIANT_CLASSES[variant],
              adornment ? "pr-1000" : "pr-400",
              hasError && "border-critical",
            )}
            name={props.name || "phoneInput"}
            placeholder={placeholder}
          />
        ) : (
          <input
            className={classNames(
              "placeholder-subdued h-full w-full border py-200 pl-400 leading-100 focus:bg-input-focus md:text-100",
              VARIANT_CLASSES[variant],
              adornment ? "pr-1000" : "pr-400",
              hasError && "border-critical",
            )}
            type={type} // Use the validated inputType
            placeholder={placeholder}
            value={numericEmptyOverride ? "" : (value as any)} // Use 'any' to bypass the type check for the input's value attribute
            onChange={onChange}
            {...props}
            ref={ref}
          />
        )}
        {adornment && typeof adornment === "string" ? (
          <Text
            color="subdued"
            className="absolute right-400 top-1/2 -translate-y-1/2"
          >
            {adornment}
          </Text>
        ) : (
          <div className="absolute right-400 top-1/2 -translate-y-1/2">
            {adornment}
          </div>
        )}
      </div>
    );
  },
);
