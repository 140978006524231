import { HTMLProps } from "react";
import { Tooltip, type TooltipProps } from "@mui/material";
import classNames from "classnames";
import SellIcon from "@mui/icons-material/Sell";

type Size = "default" | "small" | "large";

interface Product {
  id: string;
  name: string;
  variantTitle?: string;
  quantity: number;
  imageUrl?: string;
}

interface ProductThumbnailProps
  extends Omit<HTMLProps<HTMLDivElement>, "size"> {
  product: Product;
  withQuantity?: boolean;
  withTooltip?: boolean;
  stacked?: boolean;
  size?: Size;
}

export const ProductThumbnail = ({
  product,
  withQuantity = true,
  withTooltip = true,
  stacked = false,
  size = "default",
  ...props
}: ProductThumbnailProps) => {
  return (
    <BadgeWrapper
      quantity={product.quantity}
      withQuantity={withQuantity}
      size={size}
      {...props}
    >
      <TooltipWrapper
        title={`${product.name}${
          product.variantTitle ? ` / ${product.variantTitle}` : ""
        }`}
        withTooltip={withTooltip}
      >
        <div className="relative h-full w-full">
          {stacked && (
            <div className="absolute flex h-full w-full items-center justify-center rounded border bg-card-tertiary text-corrugated-500">
              <SellIcon
                className={classNames(
                  size == "large" && "h-600 w-600",
                  size == "default" && "h-500 w-500",
                  size == "small" && "h-400 w-400",
                )}
              />
            </div>
          )}
          {product.imageUrl ? (
            <img
              src={product.imageUrl}
              alt={product.name}
              className={classNames(
                "h-full w-full select-none rounded border bg-card-tertiary",
                stacked && "absolute bottom-1 left-1",
              )}
            />
          ) : (
            <div className="flex h-full w-full items-center justify-center rounded border bg-card-tertiary text-corrugated-500">
              <SellIcon
                className={classNames(
                  size == "large" && "h-600 w-600",
                  size == "default" && "h-500 w-500",
                  size == "small" && "h-400 w-400",
                )}
              />
            </div>
          )}
        </div>
      </TooltipWrapper>
    </BadgeWrapper>
  );
};

type BadgeWrapperProps<WithQuantity extends boolean> = Omit<
  HTMLProps<HTMLDivElement>,
  "size"
> & {
  withQuantity: WithQuantity;
  size: Size;
} & (WithQuantity extends true ? { quantity: number } : { quantity?: number });

const BadgeWrapper: React.FC<BadgeWrapperProps<boolean>> = ({
  children,
  quantity = 1,
  withQuantity,
  size,
  className,
  ...props
}) => {
  const showQuantityChip = withQuantity && quantity > 1;

  return (
    <div
      className={classNames(
        size == "large" && "h-1200 w-1200",
        size == "default" && "h-800 w-800",
        size == "small" && "h-600 w-600",
        showQuantityChip && "relative",
        "flex-shrink-0",
        className,
      )}
      {...props}
    >
      {showQuantityChip && (
        <div className="absolute right-[-30%] top-[-30%] z-50 aspect-square h-5 rounded-full bg-black  p-[2px] text-center text-[.7rem] text-white">
          <span>{quantity}</span>
        </div>
      )}
      {children}
    </div>
  );
};

interface TooltipWrapperProps extends TooltipProps {
  withTooltip?: boolean;
  title: string;
}

const TooltipWrapper = ({
  children,
  title,
  withTooltip,
}: TooltipWrapperProps) => {
  if (!withTooltip) return <>{children}</>;

  return <Tooltip title={title}>{children}</Tooltip>;
};
