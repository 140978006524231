import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import Link from "next/link";
import {
  Button,
  type ShapeType as ButtonShapeType,
  type VariantType as ButtonVariantType,
} from "../Button";
import { Divider } from "../Divider";
import { PageLayout } from "../PageLayout";
import { Text } from "../Text";
import { ArrowBack } from "@mui/icons-material";
import { Icon } from "../Icon";
import { ChevronLeftIcon } from "@/public/icons/solid";

type PageSecondaryAction =
  | {
      label: React.ReactNode;
      onClick: () => void;
      variant?: ButtonVariantType;
      shape?: ButtonShapeType;
      loading?: boolean;
    }
  | JSX.Element;

interface PageBreadcrumbsProps {
  backContent: React.ReactNode;
  staticContent: React.ReactNode;
  path: string;
}

interface PageHeaderProps {
  breadcrumb?: PageBreadcrumbsProps;
  description?: string | React.ReactNode;
  scrollbarOffset?: number;
  secondaryActions?: PageSecondaryAction[];
  statusBadge?: React.ReactNode | React.ReactNode[];
  title: React.ReactNode;
  withDivider?: boolean;
  className?: string;
}

interface PageProps extends React.PropsWithChildren<PageHeaderProps> {}

const Breadcrumb = ({
  backContent,
  staticContent,
  path,
}: PageBreadcrumbsProps) => (
  <div className="flex flex-row items-center gap-1">
    <Link href={path}>
      <div className="flex flex-row items-center gap-100">
        <div className="h-400 w-400 p-25">
          <Icon size="fill" fill="subdued">
            <ChevronLeftIcon />
          </Icon>
        </div>
        <Text as="span" className="select-none" color="subdued">
          {backContent}
        </Text>
      </div>
    </Link>
    <Text as="span">/ </Text>
    <Text as="span">{staticContent}</Text>
  </div>
);

const SecondaryAction = ({ action }: { action: PageSecondaryAction }) => {
  const isActionObject =
    typeof action === "object" &&
    action !== null &&
    "onClick" in action &&
    "label" in action;

  if (!isActionObject) {
    return action;
  }

  const {
    onClick,
    label,
    variant = "default",
    shape = "default",
    loading = false,
  } = action;

  return (
    <Button onClick={onClick} variant={variant} loading={loading} shape={shape}>
      {label}
    </Button>
  );
};

const PageHeader = ({
  breadcrumb,
  description,
  secondaryActions,
  statusBadge,
  title,
  withDivider = true,
}: PageHeaderProps) => (
  <div className="flex flex-col gap-400 pb-300 pt-500">
    <PageLayout>
      <div className="flex flex-col">
        <div className="flex flex-col gap-300">
          {breadcrumb ? (
            <Breadcrumb {...breadcrumb} />
          ) : (
            <div className="h-400" />
          )}
          {/* min height to accomodate button layout shift */}
          <div className="flex min-h-[2.625rem] flex-row flex-wrap justify-between gap-x-8 gap-y-2">
            <div className="flex flex-col gap-100 py-25">
              <div className="flex flex-row items-center gap-200">
                <Text as="h1" variant="heading3xl">
                  {title}
                </Text>
                {statusBadge && (
                  <div className="flex flex-row gap-2">{statusBadge}</div>
                )}
              </div>
              {description && typeof description === "string" ? (
                <Text variant="bodyMd" color="subdued">
                  {description}
                </Text>
              ) : (
                description
              )}
            </div>
            {secondaryActions && (
              <div className="flex flex-row flex-wrap items-start gap-2">
                {secondaryActions.map((action, index) => (
                  <SecondaryAction key={index} action={action} />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </PageLayout>
    <Divider color={withDivider ? "dark" : "transparent"} />
  </div>
);

export const Page = ({ children, className, ...props }: PageProps) => {
  return (
    <div
      className={"flex min-h-full w-full flex-col pb-12 " + (className || "")}
      id="OreganoPage"
    >
      <PageHeader {...props} />
      {children}
    </div>
  );
};
