import {
  TagsIcon,
  GearIcon,
  TruckIcon,
  XmarkIcon,
  DollarSignIcon,
  BoltIcon,
  BoxIcon,
  CommentIcon,
  ShopIcon,
  BarcodeScanIcon,
} from "@/public/icons/solid";
import { routes } from "@/routes";
import { useRouter } from "next/router";
import { ReactNode, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import { Icon } from "../../Icon";
import { Button } from "../../Button";
import { Text } from "../../Text";
import Link from "next/link";
import classNames from "classnames";
import { useBillingPortal } from "@/hooks/useBillingPortal";
import { useOrganizationContext } from "@/context/OrganizationContext";
import { show as showIntercomChat } from "@intercom/messenger-js-sdk";

const duration = 100;

interface MobileSidebarNavProps {
  isVisible: boolean;
  setMobileSidebarNavVisible: (arg: boolean) => void;
}

export const MobileSidebarNav = ({
  isVisible,
  setMobileSidebarNavVisible,
}: MobileSidebarNavProps) => {
  const router = useRouter();
  const { isOperator, isWorkspaceMember, pickupBeta } =
    useOrganizationContext();
  const nodeRef = useRef(null);

  return (
    <CSSTransition
      nodeRef={nodeRef}
      in={isVisible}
      timeout={duration}
      classNames={{
        enterActive: "max-w-[100vw]",
        enterDone: "max-w-[100vw]",
        exitActive: "max-w-0",
        exitDone: "max-w-0",
      }}
    >
      <div
        ref={nodeRef}
        className="fixed left-0 top-0 z-frame h-screen w-screen overflow-hidden bg-card"
        style={{
          transition: `max-width ${duration}ms ease-in-out`,
          maxWidth: 0,
          overflow: "hidden",
        }}
      >
        <div className="mb-800 p-300">
          <Button
            shape="squareSmall"
            variant="none"
            onClick={() => setMobileSidebarNavVisible(false)}
          >
            <Icon>
              <XmarkIcon />
            </Icon>
          </Button>
        </div>
        <nav className="group/navbar z-500 flex h-[calc(100svh-160px)] flex-col gap-300 border-r p-300 transition-[width]">
          {isOperator && (
            <NavButton
              title="Scan"
              route={routes.scan}
              active={routes.scan === router.pathname}
              icon={<BarcodeScanIcon />}
              closeMenu={() => setMobileSidebarNavVisible(false)}
            />
          )}
          {!isOperator && (
            <>
              {!isWorkspaceMember && pickupBeta && (
                <NavButton
                  title="Ship"
                  route={routes.home}
                  active={routes.home === router.pathname}
                  icon={<BoltIcon />}
                  closeMenu={() => setMobileSidebarNavVisible(false)}
                />
              )}
              {isWorkspaceMember && (
                <NavButton
                  title="Orders"
                  route={routes.orders}
                  active={routes.orders === router.pathname}
                  icon={<TagsIcon />}
                  closeMenu={() => setMobileSidebarNavVisible(false)}
                />
              )}
              {!isWorkspaceMember && pickupBeta && (
                <NavButton
                  title="Pickups"
                  route={routes.pickups}
                  active={routes.pickups === router.pathname}
                  icon={<TruckIcon />}
                  closeMenu={() => setMobileSidebarNavVisible(false)}
                />
              )}
              <NavButton
                title="Shipments"
                route={routes.shipments}
                active={routes.shipments === router.pathname}
                icon={<BoxIcon />}
                closeMenu={() => setMobileSidebarNavVisible(false)}
              />
              <BillingNavButton
                closeMenu={() => setMobileSidebarNavVisible(false)}
              />
            </>
          )}
          <div className="mt-auto flex flex-col gap-300">
            {!isOperator && <ChatButton />}
            <NavButton
              title="Settings"
              route={routes.settings}
              active={routes.settings === router.pathname}
              icon={<GearIcon />}
              closeMenu={() => setMobileSidebarNavVisible(false)}
            />
          </div>
        </nav>
      </div>
    </CSSTransition>
  );
};

interface NavButtonProps {
  title: string;
  route: string;
  active: boolean;
  icon: ReactNode;
  closeMenu: () => void;
}

const NavButton = ({
  title,
  route,
  active,
  icon,
  closeMenu,
}: NavButtonProps) => (
  <Link
    href={route}
    className={classNames(
      "group/navItem flex h-1000 w-auto flex-row items-center rounded transition hover:bg-card-hover",
      active && "bg-card-selected",
    )}
    onClick={closeMenu}
  >
    <div className="flex h-1000 w-1000 shrink-0 items-center justify-center">
      <Icon
        fill={active ? "active" : "default"}
        className={classNames(active ? "" : "group-hover/navItem:fill-hover")}
      >
        {icon}
      </Icon>
    </div>
    <Text
      variant={active ? "headingSm" : "subheadingSm"}
      className="text-nowrap"
    >
      {title}
    </Text>
  </Link>
);

interface BillingNavButtonProps {
  closeMenu: () => void;
}

export const BillingNavButton = ({ closeMenu }: BillingNavButtonProps) => {
  const { redirectToBillingPortal } = useBillingPortal();

  const onClick = () => {
    redirectToBillingPortal();
    closeMenu();
  };

  return (
    <div
      className={classNames(
        "group/navItem relative flex h-1000 w-auto select-none flex-row items-center rounded transition hover:cursor-pointer hover:bg-card-hover",
      )}
      onClick={onClick}
    >
      <div className="flex h-1000 w-1000 shrink-0 items-center justify-center">
        <Icon className={classNames("group-hover/navItem:fill-hover")}>
          <DollarSignIcon />
        </Icon>
      </div>
      <Text variant={"subheadingSm"}>Billing</Text>
    </div>
  );
};

const ChatButton = () => (
  <div
    className="group/navItem flex h-1000 w-auto flex-row items-center rounded transition hover:cursor-pointer hover:bg-card-hover"
    onClick={() => showIntercomChat()}
  >
    <div className="flex h-1000 w-1000 shrink-0 items-center justify-center">
      <Icon className="group-hover/navItem:fill-hover">
        <CommentIcon />
      </Icon>
    </div>
    <Text variant={"subheadingSm"}>Chat with us</Text>
  </div>
);
