import classNames from "classnames";
import { HTMLProps } from "react";

type Direction = "row" | "col";

const DIRECTION_CLASSES: Record<Direction, string> = {
  row: "flex-row [&>*]:>self-auto",
  col: "flex-col",
};

interface ActionListProps extends HTMLProps<HTMLDivElement> {
  direction?: Direction;
  flush?: boolean;
}

export const ActionList = ({
  children,
  className,
  direction = "col",
  flush = false,
}: ActionListProps) => {
  return (
    <div
      className={classNames(
        "flex gap-100 overflow-y-auto",
        DIRECTION_CLASSES[direction],
        flush ? "p-0" : "p-200",
        className,
      )}
    >
      {children}
    </div>
  );
};
