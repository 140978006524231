import { HTMLProps } from "react";
import classNames from "classnames";
import { Title } from "./components/Title";
import { Section } from "./components/Section";

export interface CardProps extends HTMLProps<HTMLDivElement> {
  variant?: Variant;
  withPadding?: boolean;
}

export const Card = ({
  children,
  className,
  variant = "default",
  withPadding = false,
  ...props
}: CardProps) => {
  return (
    <div
      className={classNames(
        "overflow-hidden rounded border",
        VARIANT_CLASSES[variant],
        withPadding && "p",
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
};

Card.Title = Title;
Card.Section = Section;

type Variant =
  | "default"
  | "dark"
  | "darkInteractive"
  | "corrugated"
  | "secondary"
  | "accent";

const VARIANT_CLASSES: Record<Variant, string> = {
  default: "bg-card text",
  // TODO (kasparisso): bg-card tokens needs to be resolved
  secondary: "bg-card-selected",
  corrugated: "bg-card-corrugated border-darkest",
  dark: "bg-card-dark text-light border-dark",
  darkInteractive: "bg-card-dark-interactive text-light border-dark",
  accent: "bg-card-accent",
};
