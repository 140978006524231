import { TopBar } from "./components/TopBar";
import classNames from "classnames";
import { SideBarNav } from "./components/SidebarNav";
import { useLayoutContext } from "@/context/LayoutContext";
import { useEffect, useState } from "react";
import { MobileSidebarNav } from "./components/MobileSidebarNav";
import { useOrganizationContext } from "@/context/OrganizationContext";

interface NotRawPageFrameProps extends React.PropsWithChildren {
  hideSidebar?: boolean;
}

export const NotRawPageFrame = ({
  children,
  hideSidebar: hideSidebarOverride,
}: NotRawPageFrameProps) => {
  const { hideSidebar, isRawPage, isScrollable } = useLayoutContext();
  const [mobileSidebarNavVisible, setMobileSidebarNavVisible] = useState(false);
  const orgContext = useOrganizationContext();
  const isWithinOrgProvider = Object.keys(orgContext).length !== 0;

  if (isRawPage) {
    return <>{children}</>;
  }

  const sidebarHidden = hideSidebar || hideSidebarOverride;

  useEffect(() => {
    if (mobileSidebarNavVisible) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "unset"; // Enable scrolling
    }

    // Cleanup function to ensure scrolling is enabled when the component unmounts
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [mobileSidebarNavVisible]);

  return (
    <div className={classNames("pt-1600", hideSidebar ? "" : "md:pl-1600")}>
      <TopBar setMobileSidebarNavVisible={setMobileSidebarNavVisible} />
      {!sidebarHidden && isWithinOrgProvider && <SideBarNav />}
      <MobileSidebarNav
        isVisible={mobileSidebarNavVisible}
        setMobileSidebarNavVisible={setMobileSidebarNavVisible}
      />
      <div
        className={classNames(
          "min-h-[calc(100svh-64px)] overflow-hidden",
          !isScrollable && "frameContent",
        )}
      >
        {children}
      </div>
    </div>
  );
};

interface FrameProps extends React.PropsWithChildren {
  hideSidebar?: boolean;
}

export const Frame = ({ children, hideSidebar }: FrameProps) => {
  const { isRawPage } = useLayoutContext();

  if (isRawPage) {
    return <>{children}</>;
  }

  return (
    <NotRawPageFrame hideSidebar={hideSidebar}>{children}</NotRawPageFrame>
  );
};
