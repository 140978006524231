import {
  Control,
  FieldErrors,
  UseFormRegister,
  useForm,
} from "react-hook-form";
import { OreganoText, OreganoHookFormTextField } from "@/components/foundation";
import { useOrganizationContext } from "@/context/OrganizationContext";

export interface EditProfileContactFormData {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  companyName: string | undefined;
}

interface EditProfileContactFormProps {
  register: UseFormRegister<EditProfileContactFormData>;
  control: Control<EditProfileContactFormData, any>;
  errors: FieldErrors<EditProfileContactFormData>;
}

export const EditProfileContactForm = ({
  register,
  control,
  errors,
}: EditProfileContactFormProps) => {
  return (
    <form
      // id={formId}
      onSubmit={(e) => e.preventDefault()}
      className="grid grid-cols-1 gap-x-200 gap-y-400 md:grid-cols-2"
    >
      <label className="flex flex-1 flex-col gap-100">
        <OreganoText>First name</OreganoText>
        <OreganoHookFormTextField
          control={control}
          type="text"
          placeholder="First name"
          variant="secondary"
          {...register("firstName", { required: true })}
          hasError={errors.firstName != null}
        />
      </label>
      <label className="flex flex-1 flex-col gap-100">
        <OreganoText>Last name</OreganoText>
        <OreganoHookFormTextField
          control={control}
          type="text"
          placeholder="Last name"
          variant="secondary"
          className="flex-1"
          {...register("lastName", { required: true })}
          hasError={errors.lastName != null}
        />
      </label>
      <label className="flex flex-1 flex-col gap-100">
        <OreganoText>Phone number</OreganoText>
        <OreganoHookFormTextField
          control={control}
          type="tel"
          variant="secondary"
          placeholder="Phone number"
          {...register("phoneNumber", { required: true })}
          hasError={errors.phoneNumber != null}
        />
      </label>
      <label className="flex flex-1 flex-col gap-100">
        <OreganoText>Company name (optional)</OreganoText>
        <OreganoHookFormTextField
          control={control}
          type="text"
          placeholder="Company name"
          variant="secondary"
          {...register("companyName")}
          hasError={errors.companyName != null}
        />
      </label>
    </form>
  );
};
