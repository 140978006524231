import { useEffect, useLayoutEffect, useState } from "react";

interface useScreenSize {
  isSmallScreen: boolean;
}

const MD_BREAKPOINT = 768;
const LG_BREAKPOINT = 1024;

// Since we're a next js app (meaning we're SSR) layout dependent on this hook's return may result in a flicker
// To avoid this, lean on CSS breakpoints as much as possible (HideWhenScreenIs component)

export const useScreenSize = () => {
  // This assumes mobile first
  const [width, setWidth] = useState(0);
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  useEffect(() => {
    function updateWidth() {
      setWidth(window.innerWidth);
    }
    if (isClient) {
      window.addEventListener("resize", updateWidth);
      updateWidth();

      return () => window.removeEventListener("resize", updateWidth);
    }
  }, [isClient]);

  return {
    isSmallScreen: width < MD_BREAKPOINT,
    isMediumScreen: width < LG_BREAKPOINT,
  };
};
