import { OreganoButton, OreganoButtonProps } from "..";

interface RadioButtonProps extends OreganoButtonProps {
  checked: boolean;
  onClick: () => void;
}

export const RadioButton = ({
  checked,
  children,
  onClick,
  ...props
}: RadioButtonProps) => {
  return (
    <OreganoButton
      variant="plain"
      shape="none"
      align="left"
      onClick={onClick}
      {...props}
    >
      <div className="flex flex-row gap-200 p-200 align-top">
        <input
          type="radio"
          checked={checked}
          className="cursor-pointer accent-corrugated-800"
          readOnly
          tabIndex={-1}
          onClick={(e) => e.preventDefault()}
        />
        {children}
      </div>
    </OreganoButton>
  );
};
