import classNames from "classnames";
import { HTMLProps } from "react";

interface SectionProps extends HTMLProps<HTMLDivElement> {}

export const Section = ({ children, className, ...props }: SectionProps) => {
  return (
    <div className={classNames("p", className)} {...props}>
      {children}
    </div>
  );
};
