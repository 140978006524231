import { HTMLProps, ReactNode } from "react";
import classNames from "classnames";
import { Text } from "./Text";
import { Icon } from "./Icon";

interface BadgeProps extends HTMLProps<HTMLDivElement> {
  status?: "success" | "critical" | "warning" | "info" | "default" | "accent";
  icon?: ReactNode;
}

export const Badge = ({
  children,
  className,
  icon,
  status = "default",
  ...props
}: BadgeProps) => {
  return (
    <div
      className={classNames(
        "inline-flex flex-row items-center gap-100 rounded-full px-2 py-1 font-semibold",
        status === "success" && "bg-badge-success",
        status === "critical" && "bg-badge-critical",
        status === "warning" && "bg-badge-warning",
        status === "info" && "bg-badge-info",
        status === "default" && "bg-badge",
        status === "accent" && "bg-badge-accent",
        className,
      )}
      {...props}
    >
      {icon && (
        <Icon size="sm" fill={status}>
          {icon}
        </Icon>
      )}
      <Text
        variant="subheadingXs"
        className="w-full overflow-hidden text-ellipsis whitespace-nowrap"
      >
        {children}
      </Text>
    </div>
  );
};
