import { OreganoText, OreganoButton } from "@/components/foundation";
import { externalLinks } from "@/routes";
import Link from "next/link";
import { Dispatch, SetStateAction, useState } from "react";

export interface ShipFromFormToggleProps {
  isShippingFromSaltboxLocation: boolean;
  setIsShippingFromSaltboxLocation: Dispatch<SetStateAction<boolean>>;
}

export const ShipFromFormToggle = ({
  isShippingFromSaltboxLocation,
  setIsShippingFromSaltboxLocation,
}: ShipFromFormToggleProps) => {
  const [isLinkHovered, setIsLinkHovered] = useState(false);

  return (
    <div className="grid grid-cols-1 gap-200 md:grid-cols-2">
      <OreganoButton
        variant="plain"
        shape="none"
        align="left"
        onClick={() => setIsShippingFromSaltboxLocation(false)}
      >
        <div className="flex flex-row gap-200 p-200 align-top">
          <input
            type="radio"
            checked={!isShippingFromSaltboxLocation}
            className="accent-corrugated-800"
            tabIndex={-1}
            readOnly
          />
          <OreganoText>I ship from my own address.</OreganoText>
        </div>
      </OreganoButton>
      <OreganoButton
        variant={isLinkHovered ? "none" : "plain"}
        shape="none"
        align="left"
        onClick={() => setIsShippingFromSaltboxLocation(true)}
      >
        <div className="flex flex-row gap-200 p-200 align-top">
          <input
            type="radio"
            checked={isShippingFromSaltboxLocation}
            className="accent-corrugated-800"
            tabIndex={-1}
            readOnly
          />
          <div className="flex flex-col gap-100 text-left">
            <OreganoText>I ship directly from a Saltbox.</OreganoText>
            <div className="self-start" onClick={(e) => e.stopPropagation()}>
              <Link
                target="_blank"
                href={externalLinks.howItWorks}
                onMouseEnter={() => setIsLinkHovered(true)}
                onMouseLeave={() => setIsLinkHovered(false)}
              >
                <OreganoText className="hover:text-info" underline>
                  Find out how
                </OreganoText>
              </Link>
            </div>
          </div>
        </div>
      </OreganoButton>
    </div>
  );
};
