import { ClientFactorySingleton } from "@/data/clientFactory";
import useSWR from "swr";
import { useGetToken } from "@/data/useGetToken";

const GET_ONBOARDING_DATA = "GET_ONBOARDING_DATA";
export function useGetOnboardingData() {
  const { getToken } = useGetToken();
  const fetcher = async () => {
    try {
      const token = await getToken();
      const client = await ClientFactorySingleton.getClient(token);

      return await client.organizationService.getOnboardingDataV2();
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  return useSWR(GET_ONBOARDING_DATA, fetcher);
}
