import { SaltboxOreganoClient, SaltboxOreganoEnvironment } from "@/apicodegen";

export function getBackendUrl() {
  if (process.env.NODE_ENV === "development") {
    if (typeof window !== "undefined") {
      const host = window.location.host.split(":")[0]; // Remove port if any
      if (host != "localhost") {
        return "https://sb-parsel-dev-be.ngrok.app";
      } else {
        return SaltboxOreganoEnvironment.Local;
      }
    } else {
      throw new Error(
        "getBackendUrl is being called in a non-browser environment",
      );
    }
  } else {
    return SaltboxOreganoEnvironment.Production;
  }
}

const NEXT_ACTIVE_ORGANIZATION_ID = "nextActiveOrganizationId";
const CURRENT_ACTIVE_ORGANIZATION_ID = "currentActiveOrganizationId";
class ActiveOrganizationManager {
  activeOrganizationId: string | null = null;

  async getActiveOrganizationId(client: SaltboxOreganoClient) {
    // This should trigger once when the page first loads
    if (this.activeOrganizationId == null) {
      const nextActiveOrganizationId = localStorage.getItem(
        NEXT_ACTIVE_ORGANIZATION_ID,
      );

      // If the user recently queued up a switch to a new organization,
      if (nextActiveOrganizationId != null) {
        localStorage.removeItem(NEXT_ACTIVE_ORGANIZATION_ID);
        localStorage.setItem(
          CURRENT_ACTIVE_ORGANIZATION_ID,
          nextActiveOrganizationId,
        );
        this.activeOrganizationId = nextActiveOrganizationId;
      } else {
        const currentActiveOrganizationId = localStorage.getItem(
          CURRENT_ACTIVE_ORGANIZATION_ID,
        );
        if (currentActiveOrganizationId != null) {
          this.activeOrganizationId = currentActiveOrganizationId;
        }
      }
    }

    return this.activeOrganizationId;
  }

  setNextActiveOrganizationId(organizationId: string, onSuccess?: () => void) {
    try {
      localStorage.setItem(NEXT_ACTIVE_ORGANIZATION_ID, organizationId);
      onSuccess && onSuccess();
    } catch (error) {
      // ignore error
    }
  }

  reset() {
    localStorage.removeItem(NEXT_ACTIVE_ORGANIZATION_ID);
    localStorage.removeItem(CURRENT_ACTIVE_ORGANIZATION_ID);
  }
}

/*
Caches a SaltboxOreganoClient based on Auth Token
*/
class ClientFactory {
  activeOrganizationManager: ActiveOrganizationManager;

  constructor(activeOrganizationManager: ActiveOrganizationManager) {
    this.activeOrganizationManager = activeOrganizationManager;
  }

  async getClient(token: string | null) {
    if (token == null) {
      throw new Error("expected token to be non-null");
    }

    const backendUrl = getBackendUrl();
    const clientWithoutOrganizationBinding = new SaltboxOreganoClient({
      environment: backendUrl,
      token,
    });

    const activeOrganizationId =
      await this.activeOrganizationManager.getActiveOrganizationId(
        clientWithoutOrganizationBinding,
      );

    const clientArgs: SaltboxOreganoClient.Options = {
      environment: backendUrl,
      token,
    };

    if (activeOrganizationId != null) {
      clientArgs.xOreganoOrganizationId = activeOrganizationId;
    }

    return new SaltboxOreganoClient(clientArgs);
  }
}

export const ActiveOrganizationManagerSingleton =
  new ActiveOrganizationManager();

export const ClientFactorySingleton = new ClientFactory(
  ActiveOrganizationManagerSingleton,
);
