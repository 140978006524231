import React, { useEffect } from "react";
import { useUser } from "@clerk/nextjs";
import Intercom from "@intercom/messenger-js-sdk";
import { useOrganizationContext } from "@/context/OrganizationContext";

const INTERCOM_APP_ID = "knkf9qdc";

export const IntercomWrapper = ({ children }: React.PropsWithChildren) => {
  const { user } = useUser();
  const { name } = useOrganizationContext();

  useEffect(() => {
    // Ensure Intercom is only called client-side as per docs
    if (user) {
      Intercom({
        app_id: INTERCOM_APP_ID,
        user_id: user.id,
        name: user.fullName ?? name ?? undefined,
        email: user.primaryEmailAddress?.emailAddress,
        created_at: user.createdAt
          ? Math.floor(user.createdAt.getTime() / 1000)
          : undefined, // unix timestamp in seconds
        hide_default_launcher: true,
      });
    }
  }, [user, name]); // Ensure the effect runs when the user or organization name changes

  return <>{children}</>;
};
