import { useRef, useState } from "react";
import classNames from "classnames";
import { CSSTransition } from "react-transition-group";
import { ChevronDownIcon, ChevronUpIcon } from "@/public/icons/solid";

import { Card, type CardProps } from "./Card";
import { OreganoButton, OreganoIcon } from ".";

interface CollapsibleCardProps extends CardProps {
  activator: React.ReactNode;
  defaultCollapsed?: boolean;
}

const duration = 200;

export const CollapsibleCard = ({
  activator,
  children,
  defaultCollapsed = true,
  ...props
}: CollapsibleCardProps) => {
  const [isCollapsed, setIsCollapsed] = useState(defaultCollapsed);

  const toggleCollapsed = () => setIsCollapsed((isCollapsed) => !isCollapsed);

  const nodeRef = useRef(null);

  return (
    <Card {...props}>
      <div
        className={classNames(
          "flex w-full flex-row items-center justify-between gap-2 p-4 transition duration-200",
          isCollapsed ? "rounded-md" : "rounded-t-md",
        )}
      >
        {activator}
        <OreganoButton variant="icon" shape="square" onClick={toggleCollapsed}>
          <OreganoIcon size="xs" fill="subdued">
            {isCollapsed ? <ChevronDownIcon /> : <ChevronUpIcon />}
          </OreganoIcon>
        </OreganoButton>
      </div>
      <CSSTransition
        nodeRef={nodeRef}
        in={!isCollapsed}
        timeout={duration}
        classNames={{
          enterActive: "max-h-[70vh]",
          enterDone: "max-h-[70vh] overflow-auto",
          exitActive: "max-h-0",
          exitDone: "max-h-0 border-transparent",
        }}
      >
        <div
          ref={nodeRef}
          className="-mt-[1px] border-t"
          style={{
            transition: `max-height ${duration}ms ease-in-out`,
            maxHeight: defaultCollapsed ? 0 : "70vh",
            overflow: "hidden",
          }}
        >
          {children}
        </div>
      </CSSTransition>
    </Card>
  );
};
