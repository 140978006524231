import useSWRMutation from "swr/mutation";
import { useGetToken } from "@/data/useGetToken";

import { ClientFactorySingleton } from "@/data/clientFactory";
import { ConfiguredPackage } from "@/apicodegen/api";
import { toast } from "react-hot-toast";

export const SET_CONFIGURED_PACKAGES = "setConfiguredPackages";

export const useSetConfiguredPackages = (
  configuredPackages?: ConfiguredPackage[],
) => {
  const { getToken } = useGetToken();
  const update: any = async () => {
    if (!configuredPackages) return;
    try {
      const token = await getToken();
      const client = await ClientFactorySingleton.getClient(token);
      await client.packageService.setConfiguredPackages({ configuredPackages });
      toast.success("Saved!");
    } catch (e) {
      toast.error("An error occurred.");
    }
  };

  return useSWRMutation<ConfiguredPackage[]>(SET_CONFIGURED_PACKAGES, update);
};
