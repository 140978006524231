import classNames from "classnames";
import { HTMLProps } from "react";

export interface CardProps extends HTMLProps<HTMLDivElement> {
  cols?: Cols;
  collapseAt?: Breakpoints;
}

export const Grid = ({
  cols = 2,
  children,
  className,
  collapseAt = "md",
}: CardProps) => {
  return (
    <div
      className={classNames(
        COLS_STYLES[collapseAt][cols],
        GRID_BASE_STYLES,
        className,
      )}
    >
      {children}
    </div>
  );
};

const GRID_BASE_STYLES = "grid gap-x";

type Cols = 2 | 3 | 4;

type Breakpoints = "md" | "lg" | "none";

const COLS_STYLES: Record<Breakpoints, Record<Cols, string>> = {
  none: { 2: "grid-cols-2", 3: "grid-cols-3", 4: "grid-cols-4" },
  md: {
    2: "grid-cols-1 md:grid-cols-2",
    3: "grid-cols-1 md:grid-cols-3",
    4: "grid-cols-1 md:grid-cols-4",
  },
  lg: {
    2: "grid-cols-1 lg:grid-cols-2",
    3: "grid-cols-1 lg:grid-cols-3",
    4: "grid-cols-1 lg:grid-cols-4",
  },
};
