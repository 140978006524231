import { ReactNode } from "react";
import { Icon } from "@/components/foundation/Icon";
import { Text, TextProps } from "@/components/foundation/Text";

interface TitleProps extends TextProps {
  icon?: ReactNode;
}

export const Title = ({ children, icon, ...props }: TitleProps) => {
  return (
    <div className="flex flex-row items-center gap-xs">
      {icon && (
        <Icon size="sm" fill="active" className="-mt-50">
          {icon}
        </Icon>
      )}
      <Text as="h3" variant="headingMd" {...props}>
        {children}
      </Text>
    </div>
  );
};
