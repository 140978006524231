import { HTMLProps } from "react";
import classNames from "classnames";

interface PageLayoutProps extends HTMLProps<HTMLDivElement> {
  layout?: Layout;
  flush?: boolean;
}

export const PageLayout = ({
  children,
  className,
  layout = "full",
  flush = false,
  ...props
}: PageLayoutProps) => {
  return (
    <div
      {...props}
      className={classNames(
        "mx-auto w-full max-w-[84rem]",
        !flush && "px-4 md:px-10 lg:px-14",
        LAYOUT_STYLES[layout],
        className,
      )}
    >
      {children}
    </div>
  );
};

type Layout = "full" | "split" | "subNav";

const LAYOUT_STYLES: Record<Layout, string> = {
  full: "",
  split: "grid grid-cols-1 lg:grid-cols-split gap",
  subNav:
    "grid grid-cols-1 md:grid-cols-2 md:grid-cols-subNavMd lg:grid-cols-subNav gap",
};
