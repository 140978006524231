import { Badge } from "../../Badge";
import { useGetOrganization } from "@/hooks/api/organization/useGetOrganization";
import { useGetShippingOrigins } from "@/hooks/onboarding";
import { LocationDotIcon } from "@/public/icons/solid";
import classNames from "classnames";
import { useState } from "react";
import EditOriginAddressModal from "@/components/shared/EditOriginAddressModal";
import { Button } from "../../Button";
import EditUnifiedProfileDetailsModal from "@/components/shared/editProfileDetails/EditUnifiedProfileDetailsModal";

export const TopBarShippingFromBadge = ({
  className,
}: React.HTMLProps<HTMLAnchorElement>) => {
  const { data, isLoading: organizationLoading } = useGetOrganization();
  const { data: shippingOrigins, isLoading: shippingOriginsLoading } =
    useGetShippingOrigins();
  const [showAddressModal, setShowAddressModal] = useState(false);

  const isLoading = organizationLoading || shippingOriginsLoading;

  const shippingFromLocation =
    data?.saltboxLocation || (shippingOrigins && shippingOrigins[0]);

  if (isLoading || !shippingFromLocation) {
    return null;
  }

  return (
    <div
      className={classNames(
        "mt-50 max-w-[280px] shrink grow-0 overflow-hidden",
        className,
      )}
    >
      <Button
        shape="none"
        variant="none"
        onClick={() => setShowAddressModal(true)}
        className="w-full"
      >
        <Badge
          icon={<LocationDotIcon />}
          className="w-full transition hover:bg-white"
        >
          {`Shipping from ${
            data?.saltboxLocation
              ? shippingFromLocation.name
              : shippingFromLocation.address.address1
          }`}
        </Badge>
      </Button>

      <EditUnifiedProfileDetailsModal
        closeModal={() => setShowAddressModal(false)}
        showModal={showAddressModal}
      />

      {/* <EditOriginAddressModal
        closeModal={() => setShowAddressModal(false)}
        showModal={showAddressModal}
      /> */}
    </div>
  );
};
