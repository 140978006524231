import useSWR from "swr";
import {
  ActiveOrganizationManagerSingleton,
  ClientFactorySingleton,
} from "@/data/clientFactory";
import { useGetToken } from "@/data/useGetToken";
import { useUser } from "@clerk/nextjs";

export const GET_ORGANIZATION = "getOrganization";

export const useGetOrganization = () => {
  const { user } = useUser();
  const { getToken } = useGetToken();
  const fetcher = async () => {
    try {
      const token = await getToken();
      const client = await ClientFactorySingleton.getClient(token);
      return await client.organizationService.getOrganization();
    } catch (error) {
      if (error instanceof Error) {
        let jsonBody = null;
        try {
          jsonBody = JSON.stringify(error);
        } catch (e) {
          console.error("error stringifying error in useGetOrganization");
        }

        if (jsonBody != null) {
          if (jsonBody.includes("unauthorized based on org id header")) {
            const errMsg =
              "resetting active org id because current user does not have access";
            console.error(errMsg);
            ActiveOrganizationManagerSingleton.reset();
            window.location.reload();
            throw new Error(errMsg);
          } else if (jsonBody.toLowerCase().includes("unauthorized")) {
            console.error("unauthorized resp in useGetOrganization");
            ActiveOrganizationManagerSingleton.reset();
            window.location.reload();
            throw error;
          }
        }

        const msg = `error encountered in useGetOrganization: ${error}`;
        console.error(msg);
        throw new Error(msg);
      } else {
        const msg = `error encountered in useGetOrganization: ${error}`;
        console.error(msg);
        throw new Error(msg);
      }
    }
  };

  return useSWR(
    user != null ? `${GET_ORGANIZATION}_${user.id}` : null,
    fetcher,
  );
};
