import { useCallback, useEffect, useState } from "react";

export interface useLocalStorage<T> {
  getValue(defaultValue: T): T;
  updateValue(value: T): void;
  removeValue(): void;
  value: T;
}

export default function useLocalStorage<T>(key: string, defaultValue: T) {
  const getValue = useCallback(
    (defaultValue: T): T => {
      try {
        const valueFromLocalStorage = window.localStorage.getItem(key);
        if (valueFromLocalStorage != null) {
          return JSON.parse(valueFromLocalStorage) as T;
        }
      } catch (error) {
        // ignore error
      }
      return defaultValue;
    },
    [key],
  );

  const [value, setValue] = useState(getValue(defaultValue));

  useEffect(() => setValue(getValue(defaultValue)), [key]);

  const removeValue = useCallback(() => {
    try {
      window.localStorage.removeItem(key);
    } catch (error) {
      // ignore error
    } finally {
      setValue(defaultValue);
    }
  }, [key, defaultValue]);

  const updateValue = useCallback(
    (value: T) => {
      try {
        window.localStorage.setItem(key, JSON.stringify(value));
      } catch (error) {
        // ignore error
      } finally {
        setValue(value);
      }
    },
    [key],
  );

  return {
    getValue,
    updateValue,
    removeValue,
    value,
  };
}
