import { SaltboxLocationId } from "@/apicodegen/api";
import { ClientFactorySingleton } from "@/data/clientFactory";
import { useRouter } from "next/router";
import { useCallback, useState } from "react";
import { useGetOrganization } from "../api/organization/useGetOrganization";
import { useGetShippingOrigins } from "./useGetShippingOrigins";
import { useGetToken } from "@/data/useGetToken";
import useSWRMutation from "swr/mutation";

interface useSetSaltboxLocationProps {
  id: SaltboxLocationId | undefined;
  onSuccess?: () => void;
  onError?: () => void;
}

export function useSetSaltboxLocation({
  id,
  onSuccess,
  onError,
}: useSetSaltboxLocationProps) {
  const { mutate: mutateSaltboxLocation } = useGetOrganization();
  const { mutate: mutateShippingOrigins } = useGetShippingOrigins();
  const { getToken } = useGetToken();

  const mutator = async () => {
    try {
      const token = await getToken();
      const client = await ClientFactorySingleton.getClient(token);
      await client.locationService.setSaltboxLocation(id);

      await mutateSaltboxLocation();
      await mutateShippingOrigins();
    } catch (e) {
      console.log(e);
      throw e;
    }
  };

  return useSWRMutation("SET_SALTBOX_LOCATION", mutator, {
    onSuccess,
    onError,
  });
}
