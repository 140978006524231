import React, {
  useState,
  useRef,
  useEffect,
  HTMLProps,
  useLayoutEffect,
  Fragment,
} from "react";
import classNames from "classnames";
import { OreganoCard } from "@/components/foundation";
import {
  SIDEBAR_WIDTH,
  TOPBAR_HEIGHT,
} from "@/components/foundation/variables";
import {
  useCreatePopover,
  usePopoverPortalContext,
} from "@/context/PopoverContext";
import { CSSTransition } from "react-transition-group";
import { useLayoutContext } from "@/context/LayoutContext";

interface PopoverProps extends HTMLProps<HTMLDivElement> {
  activator: React.ReactNode;
  activatorClassName?: string;
  children: React.ReactNode;
  position?: "top" | "bottom";
  preferredAlignment?: "left" | "right";
  isVisible: boolean;
  setIsVisible: (visible: boolean) => void;
  disabled?: boolean;
  // TODO (kasparisso): Remove this via refactor and removing button wrapper
  activatorIsButton?: boolean;
  ignoreSidebar?: boolean;
}

const Y_MARGIN = 4;
const X_MARGIN = 0;

const STICKY_BOTTOM_BAR_HEIGHT = 68; // 64 height with some spacing

export const Popover = ({
  activator,
  position: deprecatedPosition = "bottom",
  preferredAlignment = "left",
  activatorClassName = "",
  className,
  children,
  isVisible,
  setIsVisible,
  disabled = false,
  activatorIsButton = true,
  ignoreSidebar = false,
}: PopoverProps) => {
  const { sidebarVisible } = useLayoutContext();
  const { createPopover, setHasActivePopoverRenderedBelow } =
    useCreatePopover();
  const { containerRef, isInModal } = usePopoverPortalContext();
  const componentRef = useRef<HTMLDivElement | null>(null);
  const popoverRef = useRef<HTMLDivElement | null>(null);

  const [yPos, setYPos] = useState(0);
  const [xPos, setXPos] = useState(0);
  const [position, setPosition] = useState<string | undefined>(undefined);

  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  useLayoutEffect(() => {
    if (
      isVisible &&
      componentRef.current &&
      popoverRef.current &&
      containerRef?.current
    ) {
      const { yPos, xPos, position, forcedToRenderBelow } = getPopoverPosition({
        component: componentRef.current,
        popover: popoverRef.current,
        container: containerRef.current,
        preferredAlignment,
        sidebarVisible: ignoreSidebar ? false : sidebarVisible,
      });

      setYPos(yPos);
      setXPos(xPos);
      setPosition(position);
      setHasActivePopoverRenderedBelow(forcedToRenderBelow);
    }
  }, [isVisible, sidebarVisible, ignoreSidebar]);

  useEffect(() => {
    // Adds event listener to close popover when clicking outside of it
    const handleClickOutside = (event: MouseEvent) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target as Node) &&
        popoverRef.current &&
        !popoverRef.current.contains(event.target as Node)
      ) {
        setIsVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // restore default when closed
    if (!isVisible) {
      setYPos(0);
      setXPos(0);
      setPosition(undefined);
      setHasActivePopoverRenderedBelow(false);
    }
  }, [
    isVisible,
    setYPos,
    setXPos,
    setPosition,
    setHasActivePopoverRenderedBelow,
  ]);

  if (!isClient) {
    return null;
  }

  return (
    <div ref={componentRef} className={classNames("relative", className)}>
      {activatorIsButton ? (
        <button
          onClick={() => setIsVisible(!isVisible)}
          className={classNames(
            "relative h-full w-full duration-200 disabled:cursor-not-allowed",
            activatorClassName,
          )}
          disabled={disabled}
          type="button"
        >
          {activator}
        </button>
      ) : (
        <Fragment>{activator}</Fragment>
      )}
      {isVisible &&
        createPopover(
          <CSSTransition
            in={isVisible}
            nodeRef={popoverRef}
            timeout={300}
            classNames={{
              appearActive:
                position === "above" ? "-translate-y-100" : "translate-y-100",
              appearDone:
                position === "above" ? "-translate-y-100" : "translate-y-100",
              enterActive:
                position === "above" ? "-translate-y-100" : "translate-y-100",
              enterDone:
                position === "above" ? "-translate-y-100" : "translate-y-100",
              exit: "opacity-0",
            }}
            unmountOnExit
            appear
          >
            <div
              ref={popoverRef}
              className={classNames(
                "absolute transition-transform",
                isInModal ? "z-modalPopover" : "z-popover",
              )}
              style={{
                top: yPos,
                left: xPos,
                minWidth: componentRef.current?.offsetWidth,
              }}
            >
              <OreganoCard className="mt-0 overflow-hidden bg-white shadow">
                {children}
              </OreganoCard>
            </div>
          </CSSTransition>,
        )}
    </div>
  );
};

export default Popover;

interface GetPopoverPositionProps {
  component: HTMLDivElement;
  popover: HTMLDivElement;
  container: HTMLDivElement;
  preferredAlignment: "left" | "right";
  preferredPosition?: "top" | "bottom";
  sidebarVisible: boolean;
}

function getPopoverPosition({
  component,
  popover,
  container,
  preferredAlignment = "left",
  sidebarVisible,
}: GetPopoverPositionProps) {
  let yPos;
  let xPos;
  let position = "below";
  let forcedToRenderBelow = false;

  const sidebarWidth = sidebarVisible ? SIDEBAR_WIDTH : 0;
  const componentRect = component.getBoundingClientRect();
  const popoverRect = popover.getBoundingClientRect();
  const containerRect = container.getBoundingClientRect();

  const canRenderBelowInWindowView =
    componentRect.bottom + popoverRect.height + Y_MARGIN <
    window.innerHeight - STICKY_BOTTOM_BAR_HEIGHT;
  const canRenderAboveInWindowView =
    componentRect.top - popoverRect.height - Y_MARGIN > TOPBAR_HEIGHT;
  const canRenderBelowInContainerView =
    componentRect.bottom + popoverRect.height + Y_MARGIN <
    containerRect.bottom - STICKY_BOTTOM_BAR_HEIGHT;
  // temp fix
  const canRenderAboveInContainerView =
    componentRect.top - popoverRect.height - Y_MARGIN > TOPBAR_HEIGHT;

  if (canRenderBelowInWindowView) {
    yPos =
      componentRect.bottom + Y_MARGIN - containerRect.top + container.scrollTop;
  } else if (canRenderAboveInWindowView) {
    yPos =
      componentRect.top -
      popoverRect.height -
      containerRect.top +
      container.scrollTop -
      Y_MARGIN;
    position = "above";
  } else if (canRenderBelowInContainerView) {
    yPos =
      componentRect.bottom + Y_MARGIN - containerRect.top + container.scrollTop;
  } else if (canRenderAboveInContainerView) {
    yPos =
      componentRect.top -
      popoverRect.height -
      containerRect.top +
      container.scrollTop -
      Y_MARGIN;
    position = "above";
  } else {
    yPos =
      componentRect.bottom + Y_MARGIN - containerRect.top + container.scrollTop;
    forcedToRenderBelow = true;
  }

  const popoverRelativeMaxRight = componentRect.left + popoverRect.width;
  const popoverRelativeMaxLeft =
    Math.min(componentRect.right, containerRect.right) -
    popoverRect.width -
    X_MARGIN;

  const canRenderLeftAlignedInWindowView =
    popoverRelativeMaxRight < containerRect.right;
  // containerRect.left should be the same value as SIDEBAR_WIDTH
  const canRenderRightAlignedInWindowView =
    popoverRelativeMaxLeft > containerRect.left + sidebarWidth;

  if (preferredAlignment === "left") {
    if (canRenderLeftAlignedInWindowView) {
      xPos = componentRect.left - containerRect.left;
    } else if (canRenderRightAlignedInWindowView) {
      xPos = popoverRelativeMaxLeft;
    } else {
      xPos = componentRect.left; // Default if neither is possible
    }
  } else if (preferredAlignment === "right") {
    if (canRenderRightAlignedInWindowView) {
      xPos = popoverRelativeMaxLeft;
    } else if (canRenderLeftAlignedInWindowView) {
      xPos = componentRect.left;
    } else {
      xPos = componentRect.left; // Default if neither is possible
    }
  } else {
    xPos = componentRect.left;
  }

  return {
    yPos,
    xPos,
    position,
    forcedToRenderBelow,
  };
}
