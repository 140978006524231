import { useRef } from "react";
import useSWR from "swr";

export default function useVersionRefresher() {
  const { data } = useGetVersion();
  const triggeredRefresh = useRef(false);
  const trackedVersion = useRef<string | undefined>();

  if (data != null) {
    if (trackedVersion.current == null) {
      trackedVersion.current = data;
    } else if (trackedVersion.current !== data && !triggeredRefresh.current) {
      console.log(
        `Detected front-end version upgrade from ${trackedVersion.current} to ${data}. Refreshing...`,
      );
      window.location.reload();
      triggeredRefresh.current = true;
    }
  }
}

function useGetVersion() {
  const fetcher = async () => {
    try {
      const resp = await fetch("/api/version");
      const body = await resp.json();
      if (body != null) {
        const version = body["version"];
        if (version == null) {
          return null;
        } else {
          return version as string;
        }
      } else {
        return null;
      }
    } catch (e) {
      console.error("Error getting version:");
      console.error(e);
      throw e;
    }
  };

  return useSWR("GET_VERSION", fetcher, { refreshInterval: 10000 });
}
