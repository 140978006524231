import { useUser } from "@clerk/nextjs";
import { useEffect } from "react";

let identified: Record<string, boolean> = {};

function fullStoryIdentify(
  userId: string | undefined,
  userEmail: string | undefined,
) {
  if (userEmail != null && userId != null) {
    try {
      if (!identified[userEmail]) {
        // @ts-ignore
        FS.identify(userId, {
          displayName: userEmail,
          email: userEmail,
        });
        identified[userEmail] = true;
      }
    } catch (e) {
      console.log(`Error calling FS.identify: ${e}`);
    }
  }
}

export function useFullstoryId() {
  const user = useUser();

  useEffect(() => {
    const intervalId = setInterval(() => {
      fullStoryIdentify(
        user.user?.id,
        user.user?.primaryEmailAddress?.toString(),
      );
    }, 2000);

    return () => {
      clearInterval(intervalId);
    };
  }, [user]);
}
