import { OnboardingDataV2 } from "@/apicodegen/api";
import { ClientFactorySingleton } from "@/data/clientFactory";
import toast from "react-hot-toast";
import { useGetOrganization } from "../api/organization/useGetOrganization";
import { useGetToken } from "@/data/useGetToken";
import { useGetOnboardingData } from "./useGetOnboardingData";
import useSWRMutation from "swr/mutation";

interface PartialProfile {
  companyName: string | undefined;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  skippedShopifySync: boolean;
}

interface useSetOnboardingProfileProps {
  onSuccess?: () => void;
  onError?: () => void;
}

export const useSetOnboardingProfile = ({
  onSuccess,
  onError,
}: useSetOnboardingProfileProps) => {
  const { mutate: mutateOnboardingData } = useGetOnboardingData();
  const { mutate: mutateGetOrg } = useGetOrganization();
  const { getToken } = useGetToken();

  const mutator = async (
    _: any,
    { arg: partialProfile }: { arg: PartialProfile },
  ) => {
    const token = await getToken();
    const client = await ClientFactorySingleton.getClient(token);

    let companyName = partialProfile.companyName;

    if (
      companyName == null ||
      companyName.length === 0 ||
      companyName === "Default"
    ) {
      companyName = `${partialProfile.firstName} ${partialProfile.lastName}`;
    }

    const data: OnboardingDataV2 = {
      companyName,
      firstName: partialProfile.firstName,
      lastName: partialProfile.lastName,
      phoneNumber: partialProfile.phoneNumber,
      skippedShopifySync: partialProfile.skippedShopifySync,
    };

    try {
      await client.organizationService.setOnboardingDataV2(data);
      await mutateOnboardingData(data);
      // Refetch org name to update frame
      await mutateGetOrg();
    } catch (e) {
      console.log(e);
      toast.error("Whoops, that didn't work");
    }
  };

  return useSWRMutation("SET_ONBOARDING_PROFILE", mutator, {
    onSuccess,
    onError,
  });
};
