import { useOrganizationContext } from "@/context/OrganizationContext";
import {
  EditProfileContactForm,
  EditProfileContactFormData,
} from "./EditProfileContactForm";
import { useForm } from "react-hook-form";
import {
  OreganoButton,
  OreganoCard,
  OreganoDivider,
  OreganoIcon,
  OreganoModal,
  OreganoText,
} from "@/components/foundation";
import { PopoverPortalContextProvider } from "@/context/PopoverContext";
import { useSetOnboardingProfile } from "@/hooks/onboarding";
import { useSetSaltboxLocation } from "@/hooks/onboarding/useSetSaltboxLocation";
import { useSetShippingOrigins } from "@/hooks/onboarding/useSetShippingOrigins";
import { XmarkIcon } from "@/public/icons/solid";
import { useCallback, useState } from "react";
import {
  ShipFromFormToggle,
  ShipFromSaltboxLocationForm,
} from "../originAddress";
import {
  EditFromOwnAddressForm,
  EditFromOwnAddressFormData,
} from "./EditFromOwnAddressForm";
import toast from "react-hot-toast";
import { useGetShopifyShopInfo } from "@/hooks/onboarding/useGetShopifyShopInfo";

interface ShopifyShopInfo {
  firstName: string;
  lastName: string;
  companyName: string;
  phoneNumber: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
}

interface LoadedEditProfileDetailsModalProps {
  closeModal: () => void;
  showModal: boolean;
  title?: string;
  description?: string;
  saveCallback?: () => void;
  isRequired?: boolean;
  shopifyShopInfo: Partial<ShopifyShopInfo> | undefined;
}
const LoadedEditProfileDetailsModal = ({
  closeModal,
  showModal,
  title,
  description,
  saveCallback,
  isRequired = false,
  shopifyShopInfo,
}: LoadedEditProfileDetailsModalProps) => {
  const {
    shippingOrigin,
    organizationSaltboxLocation,
    saltboxLocations: saltboxLocationsUnsorted,
    onboardingData: profile,
  } = useOrganizationContext();
  const saltboxLocations = saltboxLocationsUnsorted.sort((a, b) =>
    a.name.localeCompare(b.name),
  );
  const [isShippingFromSaltboxLocation, setIsShippingFromSaltboxLocation] =
    useState<boolean>(organizationSaltboxLocation != null);
  const [selectedSaltboxLocation, setSelectedSaltboxLocation] = useState(
    organizationSaltboxLocation || saltboxLocations[0],
  );

  const {
    trigger: triggerSetOnboardingProfile,
    isMutating: isOnboardingProfileMutating,
  } = useSetOnboardingProfile({});

  const {
    trigger: triggerSetShippingOrigins,
    isMutating: isShippingOriginsMutating,
  } = useSetShippingOrigins({});

  const {
    trigger: triggerSetSaltboxLocation,
    isMutating: isSaltboxLocationsMutating,
  } = useSetSaltboxLocation({
    id: isShippingFromSaltboxLocation ? selectedSaltboxLocation.id : undefined,
  });

  const {
    register: registerProfileForm,
    control: controlProfileForm,
    handleSubmit: handleSubmitProfileForm,
    reset: resetSubmitProfileForm,
    formState: { errors: profileFormErrors },
  } = useForm<EditProfileContactFormData>({
    defaultValues:
      profile.firstName.length > 0
        ? {
            firstName: profile.firstName,
            lastName: profile.lastName,
            phoneNumber: profile.phoneNumber,
            companyName: profile.companyName,
          }
        : shopifyShopInfo != null
          ? {
              firstName: shopifyShopInfo.firstName,
              lastName: shopifyShopInfo.lastName,
              phoneNumber: shopifyShopInfo.phoneNumber,
              companyName: shopifyShopInfo.companyName,
            }
          : undefined,
    mode: "onTouched",
  });

  const {
    watch: watchFromAddressForm,
    control: controlFromAddressForm,
    register: registerFromAddressForm,
    handleSubmit: handleSubmitFromAddressForm,
    setValue: setValueFromAddressForm,
    formState: { errors: fromAddressFormErrors },
    reset: resetFromAddressForm,
  } = useForm<EditFromOwnAddressFormData>({
    defaultValues:
      shippingOrigin != null
        ? {
            address1: shippingOrigin.address.address1,
            address2: shippingOrigin.address.address2,
            city: shippingOrigin.address.city,
            state: shippingOrigin.address.state,
            zip: shippingOrigin.address.zip,
            instructions: shippingOrigin.pickupInstructions,
          }
        : shopifyShopInfo != null
          ? {
              address1: shopifyShopInfo.address1,
              address2: shopifyShopInfo.address2,
              city: shopifyShopInfo.city,
              state: shopifyShopInfo.state,
              zip: shopifyShopInfo.zip,
            }
          : undefined,
    mode: "onTouched",
  });

  const onCloseWithoutSubmit = () => {
    closeModal();
    setTimeout(() => {
      resetSubmitProfileForm();
      resetFromAddressForm();
      setIsShippingFromSaltboxLocation(organizationSaltboxLocation != null);
      setSelectedSaltboxLocation(
        organizationSaltboxLocation || saltboxLocations[0],
      );
    }, 300);
  };

  const handleSubmitSaltboxLocationForm = useCallback(() => {
    triggerSetSaltboxLocation();
  }, [triggerSetSaltboxLocation]);

  const handleUnifiedSubmit = async () => {
    try {
      let profileSuccess = false;
      let addressSuccess = false;
      await Promise.all([
        handleSubmitProfileForm(async (data) => {
          await triggerSetOnboardingProfile({
            firstName: data.firstName,
            lastName: data.lastName,
            phoneNumber: data.phoneNumber,
            companyName: data.companyName || "Default",
            skippedShopifySync: profile.skippedShopifySync,
          });
          profileSuccess = true;
        })(),
        isShippingFromSaltboxLocation
          ? handleSubmitSaltboxLocationForm()
          : handleSubmitFromAddressForm(async (data) => {
              await triggerSetShippingOrigins({
                address: {
                  address1: data.address1,
                  address2: data.address2,
                  city: data.city,
                  state: data.state,
                  zip: data.zip,
                  country: "US",
                },
                pickupInstructions: data.instructions || "",
              });
              addressSuccess = true;
            })(),
      ]);

      if (profileSuccess && (addressSuccess || isShippingFromSaltboxLocation)) {
        closeModal();
        saveCallback && saveCallback();
      }
    } catch (error) {
      toast.error("An error occured while trying to save your profile details");
    }
  };

  return (
    <OreganoModal showModal={showModal}>
      <OreganoCard className="flex flex-col">
        <OreganoCard.Section>
          <div className="flex items-center justify-between">
            <OreganoCard.Title>
              {title ? title : "Edit profile"}
            </OreganoCard.Title>
            {!isRequired && (
              <OreganoButton
                shape="squareSmall"
                variant="none"
                onClick={onCloseWithoutSubmit}
              >
                <OreganoIcon>
                  <XmarkIcon />
                </OreganoIcon>
              </OreganoButton>
            )}
          </div>
        </OreganoCard.Section>
        <OreganoDivider />
        <div className="flex-1 overflow-auto">
          <PopoverPortalContextProvider isInModal>
            {description && (
              <>
                <OreganoCard.Section>
                  <OreganoText>{description}</OreganoText>
                </OreganoCard.Section>
                <OreganoDivider />
              </>
            )}
            <OreganoCard.Section className="flex flex-col gap-sm">
              <OreganoCard.Title>Contact</OreganoCard.Title>
              <EditProfileContactForm
                register={registerProfileForm}
                control={controlProfileForm}
                errors={profileFormErrors}
              />
            </OreganoCard.Section>
            <OreganoDivider />
            <OreganoCard.Section className="flex flex-col gap">
              <div className="flex flex-col gap-xs">
                <OreganoCard.Title>Address</OreganoCard.Title>
                <ShipFromFormToggle
                  isShippingFromSaltboxLocation={isShippingFromSaltboxLocation}
                  setIsShippingFromSaltboxLocation={
                    setIsShippingFromSaltboxLocation
                  }
                />
              </div>
              {isShippingFromSaltboxLocation ? (
                <ShipFromSaltboxLocationForm
                  selectedSaltboxLocation={selectedSaltboxLocation}
                  setSelectedSaltboxLocation={setSelectedSaltboxLocation}
                  saltboxLocations={saltboxLocations}
                />
              ) : (
                <EditFromOwnAddressForm
                  watch={watchFromAddressForm}
                  control={controlFromAddressForm}
                  register={registerFromAddressForm}
                  setValue={setValueFromAddressForm}
                  errors={fromAddressFormErrors}
                />
              )}
            </OreganoCard.Section>
          </PopoverPortalContextProvider>
        </div>
        <OreganoDivider />
        <OreganoCard.Section className="flex justify-end gap-xs">
          <OreganoButton
            loading={
              isSaltboxLocationsMutating ||
              isShippingOriginsMutating ||
              isOnboardingProfileMutating
            }
            type="submit"
            onClick={handleUnifiedSubmit}
          >
            Save
          </OreganoButton>
          {!isRequired && (
            <OreganoButton variant="outline" onClick={onCloseWithoutSubmit}>
              Cancel
            </OreganoButton>
          )}
        </OreganoCard.Section>
      </OreganoCard>
    </OreganoModal>
  );
};

interface EditProfileDetailsModalProps {
  closeModal: () => void;
  showModal: boolean;
  title?: string;
  description?: string;
  saveCallback?: () => void;
  isRequired?: boolean;
  prefillWithShopifyData?: boolean;
}

export default function EditProfileDetailsModal({
  prefillWithShopifyData,
  ...props
}: EditProfileDetailsModalProps) {
  const { saltboxLocations } = useOrganizationContext();
  const { data: shopifyShopInfo, isLoading: shopifyShopInfoLoading } =
    useGetShopifyShopInfo(prefillWithShopifyData);

  if (prefillWithShopifyData && shopifyShopInfoLoading) {
    return null;
  }

  if (saltboxLocations != null) {
    return (
      <LoadedEditProfileDetailsModal
        {...props}
        shopifyShopInfo={
          prefillWithShopifyData && shopifyShopInfo
            ? {
                firstName:
                  shopifyShopInfo.shopOwnerName &&
                  shopifyShopInfo.shopOwnerName.split(" ")[0],
                lastName:
                  shopifyShopInfo.shopOwnerName &&
                  shopifyShopInfo.shopOwnerName.split(" ").slice(1).join(" "),
                phoneNumber: shopifyShopInfo.phoneNumber,
                companyName: shopifyShopInfo.shopName,
                address1: shopifyShopInfo.address.address1,
                address2: shopifyShopInfo.address.address2,
                city: shopifyShopInfo.address.city,
                state: shopifyShopInfo.address.state,
                zip: shopifyShopInfo.address.zip,
              }
            : undefined
        }
      />
    );
  }

  return <></>;
}
