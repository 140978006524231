import React, { HTMLProps, useMemo } from "react";
import classNames from "classnames";
import { OreganoIcon } from ".";
import { CircleQuestionIcon } from "@/public/icons/solid";
import { Tooltip } from "@mui/material";

export interface Column<T extends string> {
  key: T;
  title: string;
  align?: "left" | "center" | "right";
  // TODO (kasparisso): Add check for this prop to show tooltip
  tooltipContent?: string;
  width?: number;
  minWidth?: number;
}

export type Rows<T extends string[]> = {
  [K in T[number]]: React.ReactNode;
};

interface DataTableProps<T extends string[]>
  extends Omit<HTMLProps<HTMLTableElement>, "rows"> {
  columns: Column<T[number]>[];
  rows: Rows<T>[];
  flush?: boolean;
  text?: "small" | "default";
  align?: "center" | "top";
  spacing?: "default" | "tight";
}

export const DataTable = <T extends string[]>({
  columns,
  rows,
  flush = false,
  text = "default",
  align = "center",
  spacing = "default",
}: DataTableProps<T>) => {
  const renderedRows = useMemo(() => {
    return rows.map((row, rowIndex) => (
      <tr key={rowIndex}>
        {columns.map((column) => (
          <td
            key={column.key}
            className={classNames(
              "first:pl-0",
              spacing === "default" && "px-400 pt-400",
              spacing === "tight" && "px-200 pt-400",
              align === "center" && "",
              align === "top" && "align-top",
            )}
          >
            {row[column.key]}
          </td>
        ))}
      </tr>
    ));
  }, [rows, columns]);

  return (
    <div
      className={classNames(
        "w-full overflow-x-auto py-400",
        flush ? "px-0" : "px-400",
      )}
    >
      <table className="min-w-full table-auto">
        <thead>
          <tr className="border-b border-dark">
            {columns.map(
              ({ title, align = "left", width, minWidth, tooltipContent }) => (
                <th
                  key={title}
                  className={classNames(
                    "whitespace-nowrap font-semibold first:pl-0",
                    spacing === "default" && "px-400 pb-200",
                    spacing === "tight" && "px-200 pb-200",
                    align == "left" && "text-left",
                    align == "center" && "text-center",
                    align == "right" && "text-right",
                    text == "small" && "text-75 leading-100",
                  )}
                  style={{ width: `${width}px`, minWidth: `${minWidth}px` }}
                >
                  <div className="flex flex-row items-center gap-200">
                    {title}
                    {tooltipContent && (
                      <Tooltip title={tooltipContent}>
                        <OreganoIcon size="sm" fill="active">
                          <CircleQuestionIcon />
                        </OreganoIcon>
                      </Tooltip>
                    )}
                  </div>
                </th>
              ),
            )}
          </tr>
        </thead>
        <tbody>{renderedRows}</tbody>
      </table>
    </div>
  );
};
