import { useAuth } from "@clerk/nextjs";

export function useGetToken() {
  const { getToken } = useAuth();

  return {
    getToken: async () => {
      try {
        var token = await getToken();
        if (token == null) {
          // Try a few more times. this is a hack that i don't understand with clerk
          token = await getToken();
          token = await getToken();
          token = await getToken();

          if (token == null) {
            throw new Error("expected token to be non-null");
          }
        }

        return token;
      } catch (e) {
        throw new Error(`error getting token from clerk: ${e}`);
      }
    },
  };
}
