import classNames from "classnames";
import { Icon } from "./Icon";
import { CircleExclamationIcon } from "@/public/icons/solid";
import { OreganoText } from ".";

interface FullErrorPageProps {
  adjustingForTopBar?: boolean;
  message?: string;
}

export function FullErrorPage({
  adjustingForTopBar = true,
  message = "Oops, something went wrong.",
}: FullErrorPageProps) {
  return (
    <div
      className={classNames(
        "flex w-full justify-center align-middle",
        adjustingForTopBar ? "h-[calc(100svh-64px)]" : "h-full",
      )}
    >
      <div className="flex flex-row items-center gap-2">
        <Icon size="sm">
          <CircleExclamationIcon />
        </Icon>
        <OreganoText>{message}</OreganoText>
      </div>
    </div>
  );
}
