import parselLogo from "@/public/parsel_logo_white.png";
import Image from "next/image";
import Link from "next/link";
import { TopBarOrgNav } from "./TopBarOrgNav";
import { externalLinks, routes } from "@/routes";
import { Button } from "../../Button";
import { Icon } from "../../Icon";
import { BarsIcon } from "@/public/icons/solid";
import { useLayoutContext } from "@/context/LayoutContext";
import { HideWhenScreenIs } from "../../HideWhenScreenIs";
import classNames from "classnames";
import { useOrganizationContext } from "@/context/OrganizationContext";
import { TopBarShippingFromBadge } from "./TopBarShippingFromBadge";
import { OreganoBadge, OreganoTag, OreganoText } from "../..";

interface TopBarProps {
  setMobileSidebarNavVisible: (arg: boolean) => void;
}

export const TopBar = ({ setMobileSidebarNavVisible }: TopBarProps) => {
  const { hideSidebar, preventUserScroll, isScrollable, scrollbarWidth } =
    useLayoutContext();

  return (
    <div
      className={classNames(
        "fixed left-0 top-0 z-frame h-1600 w-full bg-dark py-300 pl-300",
      )}
      style={{
        paddingRight:
          preventUserScroll && isScrollable
            ? `calc(12px + ${scrollbarWidth}px`
            : "12px",
      }}
    >
      <div className="flex h-full flex-row items-center justify-between gap-400">
        <HideWhenScreenIs
          smallest
          xs
          sm
          className="flex h-full w-auto flex-none flex-row items-center gap-300 py-100"
        >
          <Link href={routes.home} className="h-full select-none">
            <Image
              priority
              src={parselLogo}
              alt="parsel logo"
              className="h-full w-auto"
            />
          </Link>
          <Link href={externalLinks.saltboxHomepage} target="_blank">
            <OreganoBadge className="mt-50 transition hover:bg-brand-yellow">
              Powered by Saltbox
            </OreganoBadge>
          </Link>
        </HideWhenScreenIs>
        <HideWhenScreenIs md lg>
          {!hideSidebar ? (
            <Button
              shape="squareSmall"
              variant="none"
              onClick={() => setMobileSidebarNavVisible(true)}
            >
              <Icon fill="white">
                <BarsIcon />
              </Icon>
            </Button>
          ) : null}
        </HideWhenScreenIs>

        <HideWhenScreenIs
          md
          lg
          className="fixed left-1/2 flex w-1200 -translate-x-1/2 items-center"
        >
          <Link href={routes.home} className="h-full select-none">
            <Image
              priority
              src={"/parsel_icon.svg"}
              width={50}
              height={50}
              alt="parsel logo"
              className="h-auto w-full"
            />
          </Link>
        </HideWhenScreenIs>
        <div className="hidden flex-1 md:inline" />
        <HideWhenScreenIs smallest xs sm>
          <TopBarShippingFromBadge />
        </HideWhenScreenIs>
        <TopBarOrgNav />
      </div>
    </div>
  );
};
