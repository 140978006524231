import { ClientFactorySingleton } from "./clientFactory";
import useSWR from "swr";
import { useGetToken } from "./useGetToken";
import { useState } from "react";
import { ShopifyConnection } from "@/apicodegen/api";
import useSWRMutation from "swr/mutation";
import toast from "react-hot-toast";

export function useStoreConnections() {
  const [shopify, setShopify] = useState<ShopifyConnection | undefined>();
  const { getToken } = useGetToken();
  const storeConnectionsFetcher = async () => {
    try {
      const token = await getToken();
      const client = await ClientFactorySingleton.getClient(token);
      const resp = await client.storeConnections.storeConnections();
      setShopify(resp.shopify);
      return resp;
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  return useSWR("STORE_CONNECTIONS", storeConnectionsFetcher, {
    refreshInterval:
      shopify != null && !shopify.initialSyncCompleted ? 2000 : undefined,
  });
}

export const DELETE_SHOPIFY_CONNECTION_KEY = "DELETE_SHOPIFY_CONNECTION";

export function useDeleteShopifyConnection() {
  const { mutate } = useStoreConnections();
  const { getToken } = useGetToken();
  const mutator = async () => {
    try {
      const token = await getToken();
      const client = await ClientFactorySingleton.getClient(token);
      await client.storeConnections.deleteShopifyStoreConnection();
      await mutate();
    } catch (e) {
      console.error(e);
      toast.error("Whoops, that didn't work.");
      throw e;
    }
  };

  return useSWRMutation(DELETE_SHOPIFY_CONNECTION_KEY, mutator);
}
