import { ClientFactorySingleton } from "@/data/clientFactory";
import useSWR from "swr";
import { useGetToken } from "@/data/useGetToken";

const GET_SHIPPING_ORIGINS = "GET_SHIPPING_ORIGINS";
export function useGetShippingOrigins() {
  const { getToken } = useGetToken();
  const fetcher = async () => {
    try {
      const token = await getToken();
      const client = await ClientFactorySingleton.getClient(token);
      return await client.locationService.getShippingOrigins();
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  return useSWR(GET_SHIPPING_ORIGINS, fetcher);
}
