import { useState } from "react";
import { OreganoButton, OreganoIcon, OreganoPopover, OreganoText } from "..";
import { DateCalendar as MUIDateCalendar } from "@mui/x-date-pickers";
import { ChevronUpIcon, ChevronDownIcon } from "@/public/icons/solid";
import dayjs, { Dayjs } from "dayjs";

interface DatePickerProps {
  selectedDate: Dayjs;
  updateSelectedDate: (selectedDate: Dayjs) => void;
  disableFuture?: boolean;
  disablePast?: boolean;
  minDate?: Dayjs;
  maxDate?: Dayjs;
}

export const DatePicker = ({
  selectedDate,
  updateSelectedDate,
  disableFuture = false,
  disablePast = false,
  minDate = undefined,
  maxDate = undefined,
}: DatePickerProps) => {
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const isToday = selectedDate.isSame(dayjs(), "day");

  return (
    <OreganoPopover
      activator={
        <OreganoText
          variant="subheadingSm"
          className="text-nowrap border-b border-dashed border-darkest hover:border-darkest"
        >
          {isToday ? "Today" : selectedDate.format("MMM D, YYYY")}
        </OreganoText>
      }
      isVisible={isPopoverVisible}
      setIsVisible={setIsPopoverVisible}
    >
      <MUIDateCalendar
        onChange={(value) => {
          setIsPopoverVisible(false);
          updateSelectedDate(value as Dayjs);
        }}
        disableFuture={disableFuture}
        disablePast={disablePast}
        minDate={minDate}
        maxDate={maxDate}
      />
    </OreganoPopover>
  );
};
