import { ActiveOrganizationManagerSingleton } from "@/data/clientFactory";
import { useGetOrganization } from "@/hooks/api/organization/useGetOrganization";
import { useGetOrganizations } from "@/hooks/api/organization/useGetOrganizations";
import { HTMLProps, useState } from "react";
import { Divider } from "../../Divider";
import { ActionListButton, ActionList } from "../../ActionList";

export const OrganizationSwitcher = () => {
  const { data: organizations } = useGetOrganizations();
  const { data: activeOrganization } = useGetOrganization();

  const [processing, setProcessing] = useState(false);

  async function handleOnClick(orgId: string) {
    setProcessing(true);
    ActiveOrganizationManagerSingleton.setNextActiveOrganizationId(
      orgId,
      () => {
        window.location.href = "/";
      },
    );
  }

  if (!organizations || !activeOrganization || organizations.length <= 1) {
    return null;
  }

  const sortedOrganizations = organizations.sort((a, b) => {
    if (a.id === activeOrganization.id) {
      return -1;
    } else if (b.id === activeOrganization.id) {
      return 1;
    } else {
      return a.name.localeCompare(b.name);
    }
  });

  return (
    <div>
      <ActionList className="max-h-[80vh]">
        {sortedOrganizations.map((org) => (
          <ActionListButton
            key={org.id}
            disabled={processing}
            onClick={() => handleOnClick(org.id)}
            active={activeOrganization.id === org.id}
          >
            {org.name}
          </ActionListButton>
        ))}
      </ActionList>
      <Divider color="light" />
    </div>
  );
};
