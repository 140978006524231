import { ChevronUpIcon, ChevronDownIcon } from "@/public/icons/solid";
import {
  HTMLProps,
  KeyboardEventHandler,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  OreganoPopover,
  OreganoActionList,
  OreganoActionListButton,
  OreganoIcon,
} from "..";
import classNames from "classnames";
import {
  SelectContextProvider,
  useSelectContext,
} from "./context/SelectContext";

interface Option<T> {
  key: string;
  value: T;
  active: boolean;
  content: React.ReactNode;
  disabled?: boolean;
}

interface SelectProps<T> {
  activator: React.ReactNode;
  onSelect: (arg: T) => void;
  options: Option<T>[];
  ignoreSidebar?: boolean;
}

export const Select = <T,>({
  activator,
  onSelect,
  options,
  ignoreSidebar = false,
}: SelectProps<T>) => {
  const [isPopoverVisible, setIsPopoverVisible2] = useState(false);
  const [focusedOptionIndex, setFocusedOptionIndex] = useState(-1); // -1 means no option is focused

  const setIsPopoverVisible = (arg: boolean) => {
    setIsPopoverVisible2(arg);
  };

  const handleOnSelect = (value: T) => {
    onSelect(value);
    setIsPopoverVisible(false);
  };

  const handleKeyDown: KeyboardEventHandler<HTMLButtonElement> = (e) => {
    if (isPopoverVisible) {
      if (e.key === "ArrowDown") {
        e.preventDefault(); // Prevent scrolling
        setFocusedOptionIndex((prevIndex) =>
          prevIndex < options.length - 1
            ? indexOfNextValidOption(options, prevIndex)
            : prevIndex,
        );
      } else if (e.key === "ArrowUp") {
        e.preventDefault(); // Prevent scrolling
        setFocusedOptionIndex((prevIndex) =>
          prevIndex > 0 ? indexOfPrevValidOption(options, prevIndex) : 0,
        );
      } else if (e.key === "Enter" && focusedOptionIndex !== -1) {
        e.preventDefault();
        setFocusedOptionIndex(-1);
        handleOnSelect(options[focusedOptionIndex].value);
        activatorRef?.current?.focus();
      } else if (e.shiftKey && e.key === "Tab") {
        // if the popover is open and the button is in focus,
        // shift+tab should close popover and go to previous tab index
        if (focusedOptionIndex === -1) {
          setIsPopoverVisible(false);
        } else {
          e.preventDefault(); // Prevent default previous tab index
          if (focusedOptionIndex === 0) {
            setFocusedOptionIndex(-1);
            activatorRef?.current?.focus();
          } else {
            setFocusedOptionIndex((prevIndex) =>
              prevIndex > 0 ? indexOfPrevValidOption(options, prevIndex) : 0,
            );
          }
        }
      } else if (e.key === "Tab") {
        e.preventDefault(); // Prevent default next tab index
        setFocusedOptionIndex((prevIndex) =>
          prevIndex < options.length - 1
            ? indexOfNextValidOption(options, prevIndex)
            : prevIndex,
        );
      } else if (e.key === "Escape") {
        setFocusedOptionIndex(-1);
        setIsPopoverVisible(false);
        activatorRef?.current?.focus();
      }
    }
  };

  const activatorRef = useRef<HTMLButtonElement>(null);
  const focusedRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (focusedOptionIndex !== -1 && focusedRef.current) {
      focusedRef.current.focus();
    }
  }, [focusedOptionIndex]);

  return (
    <SelectContextProvider
      value={{
        isPopoverVisible,
        setIsPopoverVisible,
        handleKeyDown,
        activatorRef,
      }}
    >
      <OreganoPopover
        activator={activator}
        className="h-full"
        isVisible={isPopoverVisible}
        setIsVisible={setIsPopoverVisible}
        activatorIsButton={false}
        ignoreSidebar={ignoreSidebar}
      >
        <OreganoActionList className="max-h-[40vh]">
          {options.map(({ key, value, active, content, disabled }, index) => (
            <OreganoActionListButton
              key={key}
              onClick={() => handleOnSelect(value)}
              active={active}
              tabIndex={disabled ? -1 : 0} // Make sure it's focusable
              ref={focusedOptionIndex === index ? focusedRef : null}
              onKeyDown={handleKeyDown}
              disabled={disabled}
            >
              {content}
            </OreganoActionListButton>
          ))}
        </OreganoActionList>
      </OreganoPopover>
    </SelectContextProvider>
  );
};

interface ActivatorProps extends Omit<HTMLProps<HTMLButtonElement>, "type"> {
  withChevron?: boolean;
  disabled?: boolean;
}

const Activator = ({
  children,
  className,
  withChevron = true,
  disabled = false,
  ...props
}: ActivatorProps) => {
  const { activatorRef, isPopoverVisible, setIsPopoverVisible, handleKeyDown } =
    useSelectContext();

  return (
    <button
      onClick={() => setIsPopoverVisible(!isPopoverVisible)}
      className={classNames(
        "relative h-full w-full rounded border bg-card px-400 py-300 text-100 duration-200 enabled:hover:bg-corrugated-50 disabled:cursor-not-allowed disabled:bg-corrugated-25 disabled:text-disabled",
        withChevron && "flex flex-row items-center justify-between gap-400",
        className,
      )}
      disabled={disabled}
      type="button"
      onKeyDown={handleKeyDown}
      tabIndex={0}
      ref={activatorRef}
      {...props}
    >
      {children}
      {
        <OreganoIcon size="xs" fill="subdued">
          {isPopoverVisible ? <ChevronUpIcon /> : <ChevronDownIcon />}
        </OreganoIcon>
      }
    </button>
  );
};

Select.Activator = Activator;

const indexOfNextValidOption = <T,>(
  options: Option<T>[],
  currentIndex: number,
) => {
  const totalOptions = options.length;

  // Start from the next index
  let nextIndex = (currentIndex + 1) % totalOptions;

  // Loop through the options
  while (nextIndex !== currentIndex) {
    // Check if the current option is not disabled
    if (!options[nextIndex].disabled) {
      return nextIndex;
    }

    // Move to the next option, loop back to start if at the end
    nextIndex = (nextIndex + 1) % totalOptions;
  }

  // If all options are disabled or no valid option is found
  return currentIndex;
};

const indexOfPrevValidOption = <T,>(
  options: Option<T>[],
  currentIndex: number,
): number => {
  const totalOptions = options.length;

  // Adjust starting index, considering loop back for index 0
  let prevIndex = (currentIndex - 1 + totalOptions) % totalOptions;

  // Loop through the options
  while (prevIndex !== currentIndex) {
    // Check if the current option is not disabled
    if (!options[prevIndex].disabled) {
      return prevIndex;
    }

    // Move to the previous option, loop back to end if at the start
    prevIndex = (prevIndex - 1 + totalOptions) % totalOptions;
  }

  // If all options are disabled or no valid option is found
  return -1;
};
