import {
  ConfiguredPackage,
  OnboardingDataV2,
  SaltboxLocation,
  StoreConnections,
  ShippingOrigin,
} from "@/apicodegen/api";
import { createContext, useContext } from "react";
import { useLayoutContext } from "../LayoutContext";
import { Frame } from "@/components/foundation/Frame";
import { FullErrorPage } from "@/components/foundation/FullErrorPage";
import { FullLoadingPage } from "@/components/foundation/FullLoadingPage";
import { useStoreConnections } from "@/data/storeConnectionsHooks";
import { useGetOrganization } from "@/hooks/api/organization/useGetOrganization";
import { useGetConfiguredPackages } from "@/hooks/api/packages";
import {
  useGetOnboardingData,
  useGetShippingOrigins,
} from "@/hooks/onboarding";
import { useGetSaltboxLocations } from "@/hooks/onboarding/useGetSaltboxLocations";
import { useSkippedOnboardingShopifySyncLocalStorage } from "@/hooks/onboarding/useSkippedOnboardingShopifySyncLocalStorage";
import {
  OPERATOR_ROUTES,
  onboardingRoutes,
  routes,
  staticRoutes,
} from "@/routes";
import { useRouter } from "next/router";
export interface OrganizationState {
  id: string;
  isOperator: boolean;
  name: string;
  onboardingData: OnboardingDataV2;
  organizationSaltboxLocation: SaltboxLocation | undefined;
  hasValidPaymentMethod: boolean;
  paymentMethodNotRequiredOverride: boolean;
  canPurchaseLabels: boolean;
  isDefaultOrganization: boolean;
  isWorkspaceMember: boolean;
  pickupBeta: boolean;
  configuredPackages: ConfiguredPackage[];
  storeConnections: StoreConnections;
  hasBilling: boolean;
  saltboxLocations: SaltboxLocation[];
  saltboxLocation: SaltboxLocation | undefined;
  shippingOrigin: ShippingOrigin | undefined;
}

const OrganizationContext = createContext<OrganizationState>({} as any);

const NotRawPageOrganizationProvider = ({
  children,
}: React.PropsWithChildren) => {
  const organizationResponse = useGetOrganization();
  const onboardingResponse = useGetOnboardingData();
  const shippingOriginsResponse = useGetShippingOrigins();
  const saltboxLocationsResponse = useGetSaltboxLocations();
  const configuredPackagesResponse = useGetConfiguredPackages();
  const storeConnectionsResponse = useStoreConnections();

  // pathname does not include search params
  const router = useRouter();
  const isOnboardingRoute = Object.values(onboardingRoutes).includes(
    router.pathname,
  );

  const { getSkippedOnboardingShopifySync } =
    useSkippedOnboardingShopifySyncLocalStorage(organizationResponse.data?.id);

  const isLoading =
    organizationResponse.isLoading ||
    onboardingResponse.isLoading ||
    shippingOriginsResponse.isLoading ||
    saltboxLocationsResponse.isLoading ||
    configuredPackagesResponse.isLoading ||
    storeConnectionsResponse.isLoading;

  const hasError =
    !organizationResponse.data ||
    !shippingOriginsResponse.data ||
    !saltboxLocationsResponse.data ||
    !configuredPackagesResponse.data ||
    !storeConnectionsResponse.data;

  if (isLoading) {
    return (
      <Frame hideSidebar={true}>
        <FullLoadingPage />
      </Frame>
    );
  }

  if (!isLoading && hasError) {
    if (organizationResponse.error) {
      console.error("organizationResponse.error:", organizationResponse.error);
    }
    if (onboardingResponse.error) {
      console.error("onboardingResponse.error:", onboardingResponse.error);
    }
    if (shippingOriginsResponse.error) {
      console.error(
        "shippingOriginsResponse.error:",
        shippingOriginsResponse.error,
      );
    }
    if (saltboxLocationsResponse.error) {
      console.error(
        "saltboxLocationsResponse.error:",
        saltboxLocationsResponse.error,
      );
    }
    if (configuredPackagesResponse.error) {
      console.error(
        "configuredPackagesResponse.error:",
        configuredPackagesResponse.error,
      );
    }
    if (storeConnectionsResponse.error) {
      console.error(
        "storeConnectionsResponse.error:",
        storeConnectionsResponse.error,
      );
    }
    if (!organizationResponse.data) {
      console.error("organizationResponse.data is missing");
    }
    if (!onboardingResponse.data) {
      console.error("onboardingResponse.data is missing");
    }
    if (!shippingOriginsResponse.data) {
      console.error("shippingOriginsResponse.data is missing");
    }
    if (!saltboxLocationsResponse.data) {
      console.error("saltboxLocationsResponse.data is missing");
    }
    if (!configuredPackagesResponse.data) {
      console.error("configuredPackagesResponse.data is missing");
    }
    if (!storeConnectionsResponse.data) {
      console.error("storeConnectionsResponse.data is missing");
    }

    return (
      <Frame hideSidebar={true}>
        <FullErrorPage />
      </Frame>
    );
  }

  const saltboxLocation = saltboxLocationsResponse.data?.find(
    (l) =>
      shippingOriginsResponse.data != null &&
      shippingOriginsResponse.data.length > 0 &&
      l.id === shippingOriginsResponse.data[0].nearbySaltboxes[0],
  );

  // Rerouting logic
  if (!isOnboardingRoute) {
    let redirecting = false;

    const hasShopifyConnection = storeConnectionsResponse.data!.shopify != null;
    if (
      !hasShopifyConnection &&
      !getSkippedOnboardingShopifySync(false) &&
      (onboardingResponse.data == null ||
        !onboardingResponse.data.skippedShopifySync)
    ) {
      router.push(onboardingRoutes.onboardingShopifySync);
      redirecting = true;
    } else if (
      onboardingResponse.data == null ||
      (shippingOriginsResponse.data!.length === 0 &&
        organizationResponse.data!.saltboxLocation == null)
    ) {
      router.push(onboardingRoutes.onboardingProfile);
      redirecting = true;
    } else if (
      !OPERATOR_ROUTES.includes(router.pathname) &&
      organizationResponse.data?.isOperator
    ) {
      router.push(staticRoutes.scan);
      redirecting = true;
    }

    if (redirecting) {
      return (
        <Frame hideSidebar={true}>
          <FullLoadingPage />
        </Frame>
      );
    }
  }

  return (
    <OrganizationContext.Provider
      value={{
        id: organizationResponse.data!.id,
        isOperator: organizationResponse.data!.isOperator,
        name: organizationResponse.data!.name,
        onboardingData: onboardingResponse.data || {
          firstName: "",
          lastName: "",
          companyName: "",
          phoneNumber: "",
          skippedShopifySync: false,
        },
        organizationSaltboxLocation: organizationResponse.data!.saltboxLocation,
        saltboxLocation: saltboxLocation,
        saltboxLocations: saltboxLocationsResponse.data!,
        hasValidPaymentMethod: organizationResponse.data!.hasValidPaymentMethod,
        paymentMethodNotRequiredOverride:
          organizationResponse.data!.paymentMethodNotRequiredOverride,
        canPurchaseLabels: organizationResponse.data!.canPurchaseLabels,
        isDefaultOrganization: organizationResponse.data!.default,
        shippingOrigin: shippingOriginsResponse.data![0] || undefined,
        isWorkspaceMember: organizationResponse.data!.saltboxLocation != null,
        pickupBeta: organizationResponse.data!.pickupBeta,
        configuredPackages: configuredPackagesResponse.data!,
        storeConnections: storeConnectionsResponse.data!,
        hasBilling:
          organizationResponse.data!.hasValidPaymentMethod ||
          organizationResponse.data!.paymentMethodNotRequiredOverride,
      }}
    >
      {children}
    </OrganizationContext.Provider>
  );
};

export const OrganizationProvider = ({ children }: React.PropsWithChildren) => {
  const { isRawPage } = useLayoutContext();

  if (isRawPage) {
    return <>{children}</>;
  }

  return (
    <NotRawPageOrganizationProvider>{children}</NotRawPageOrganizationProvider>
  );
};

export const useOrganizationContext = () => useContext(OrganizationContext);
