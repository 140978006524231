import classNames from "classnames";
import {
  ReactNode,
  RefObject,
  createContext,
  useContext,
  useRef,
  useState,
} from "react";
import { createPortal } from "react-dom";

interface PopoverPortalContextState {
  containerRef: RefObject<HTMLDivElement> | null;
  isInModal: boolean;
  setHasActivePopoverRenderedBelow: (arg: boolean) => void;
}

const PopoverPortalContext = createContext<PopoverPortalContextState>({
  containerRef: null,
  isInModal: false,
  setHasActivePopoverRenderedBelow: (_arg) => {},
});

interface PopoverPortalContextProviderProps {
  children: React.ReactNode;
  className?: string;
  isInModal?: boolean;
}

export const PopoverPortalContextProvider = ({
  children,
  className = "",
  isInModal = false,
}: PopoverPortalContextProviderProps) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [hasActivePopoverRenderedBelow, setHasActivePopoverRenderedBelow] =
    useState(false);

  return (
    <PopoverPortalContext.Provider
      value={{ containerRef, isInModal, setHasActivePopoverRenderedBelow }}
    >
      <div
        id="popover_portal"
        ref={containerRef}
        className={classNames(
          "relative",
          className,
          hasActivePopoverRenderedBelow && "has",
        )}
        style={hasActivePopoverRenderedBelow ? { paddingBottom: "68px" } : {}}
      >
        {children}
      </div>
    </PopoverPortalContext.Provider>
  );
};

export const usePopoverPortalContext = () => {
  return useContext(PopoverPortalContext);
};

export const useCreatePopover = () => {
  const { containerRef, setHasActivePopoverRenderedBelow } =
    usePopoverPortalContext(); // Get the portal container from context

  const createPopover = (children: ReactNode) => {
    if (containerRef?.current) {
      return createPortal(children, containerRef.current);
    }
  };

  return {
    createPopover,
    setHasActivePopoverRenderedBelow,
  };
};
