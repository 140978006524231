import useSWR, { Fetcher } from "swr";
import { useGetToken } from "@/data/useGetToken";
import { ClientFactorySingleton } from "@/data/clientFactory";
import { ConfiguredPackage } from "@/apicodegen/api";

export const GET_CONFIGURED_PACKAGES = "getConfiguredPackages";

export const useGetConfiguredPackages = () => {
  const { getToken } = useGetToken();
  const fetcher: Fetcher<any> = async () => {
    try {
      const token = await getToken();
      const client = await ClientFactorySingleton.getClient(token);
      return await client.packageService.getConfiguredPackages();
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  return useSWR<ConfiguredPackage[]>(GET_CONFIGURED_PACKAGES, fetcher);
};
