import { useState, useEffect, useMemo } from "react";

export function useBodyScrollable() {
  const [isScrollable, setScrollable] = useState(false);
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  const scrollbarWidth = useMemo(
    () => (isClient ? getScrollbarWidth() : 0),
    [isClient]
  );

  const pageElement = isClient ? document.body : undefined;

  useEffect(() => {
    if (pageElement) {
      const resizeObserver = new ResizeObserver(() => {
        if (pageElement.style.overflow === "hidden") {
          setScrollable(false);
        } else {
          setScrollable(pageElement.scrollHeight > window.innerHeight);
        }
      });
      resizeObserver.observe(pageElement);

      return () => {
        resizeObserver.unobserve(pageElement);
      };
    }
  }, [pageElement]);

  return {
    isScrollable,
    scrollbarWidth,
  };
}

function getScrollbarWidth() {
  const outer = document.createElement("div");
  outer.style.visibility = "hidden";
  outer.style.overflow = "scroll"; // forcing scrollbar to appear
  document.body.appendChild(outer);

  const inner = document.createElement("div");
  outer.appendChild(inner);

  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;
  outer.parentNode!.removeChild(outer);

  return scrollbarWidth;
}
