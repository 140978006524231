import { HTMLProps, ReactNode } from "react";
import classNames from "classnames";
import { Fragment } from "react";
import { Icon } from "./Icon";
import { Text } from "./Text";

type Status =
  | "default"
  | "success"
  | "critical"
  | "warning"
  | "info"
  | "accent";

const STATUS_CLASSES: Record<Status, string> = {
  default: "bg-banner text",
  success: "bg-banner-success text-success-onSurface border-success",
  critical: "bg-banner-critical text-critical-onSurface border-critical",
  warning: "bg-banner-warning text-warning-onSurface border-warning",
  info: "bg-banner-info text-info-onSurface border-info",
  accent: "bg-banner-accent border-accent",
};

type Border = "default" | "dashed" | "borderless";

const BORDER_CLASSES: Record<Border, string> = {
  default: "",
  dashed: "border-dashed",
  borderless: "border-0",
};

interface BannerProps extends HTMLProps<HTMLDivElement> {
  status?: Status;
  icon?: ReactNode;
  border?: Border;
}

export const Banner = ({
  children,
  className,
  status = "default",
  icon,
  border = "default",
  ...props
}: BannerProps) => {
  return (
    <div
      className={classNames(
        "flex flex-row items-center gap-2 rounded-md border px-300 py-200",
        STATUS_CLASSES[status],
        BORDER_CLASSES[border],
        className,
      )}
      {...props}
    >
      {icon && (
        <Icon fill={status} size="sm">{icon}</Icon>
      )}
      {typeof children === "string" ? (
        <TextWrapper status={status}>{children}</TextWrapper>
      ) : (
        <Fragment>{children}</Fragment>
      )}
    </div>
  );
};

interface TextWrapperProps extends React.PropsWithChildren {
  status: Status;
}

const TextWrapper = ({ children, status }: TextWrapperProps) => {
  return (
    <div className="py-100">
      <Text color={status}>{children}</Text>
    </div>
  );
};
