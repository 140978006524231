import { HTMLProps, SyntheticEvent } from "react";

interface StopEventPropagationProps extends HTMLProps<HTMLDivElement> {}

export const StopEventPropagation = ({
  children,
  ...props
}: StopEventPropagationProps) => {
  function stopEventPropagationHandler(event: SyntheticEvent) {
    event.stopPropagation();
  }

  function stopKeyDownPropagationHandler(event: React.KeyboardEvent) {
    event.stopPropagation();
  }

  return (
    <div
      {...props}
      onClick={stopEventPropagationHandler}
      onKeyDown={stopKeyDownPropagationHandler}
    >
      {children}
    </div>
  );
};
