import { useState } from "react";
import { useInitiateCustomerPortalSession } from "./api/billing/billingHooks";
import { useGetOrganization } from "./api/organization/useGetOrganization";
import { OreganoButton, OreganoIcon } from "@/components/foundation";
import { ArrowRight } from "@mui/icons-material";

export function useBillingPortal() {
  const { data: organization } = useGetOrganization();

  const [loadingFallback, setLoadingFallback] = useState(false);
  const { isMutating, trigger } = useInitiateCustomerPortalSession();

  const retVal = {
    needsToSetupBilling:
      organization != null &&
      !organization.hasValidPaymentMethod &&
      !organization.paymentMethodNotRequiredOverride,
    billingPortalLoading: isMutating || loadingFallback,
    redirectToBillingPortal: () => {
      setLoadingFallback(true);
      setTimeout(() => {
        setLoadingFallback(false);
      }, 3000);
      trigger(null, {
        onSuccess: ({ sessionUrl }) => {
          setTimeout(() => {
            window.open(sessionUrl, "_blank");
          });
        },
      });
    },
  };

  const SetUpBillingButton = (
    <OreganoButton
      disabled={retVal.billingPortalLoading}
      loading={retVal.billingPortalLoading}
      onClick={retVal.redirectToBillingPortal}
    >
      <div className="flex flex-row items-center gap-2">
        <div>Set up billing</div>
        <OreganoIcon>
          <ArrowRight />
        </OreganoIcon>
      </div>
    </OreganoButton>
  );

  return {
    ...retVal,
    SetUpBillingButton,
  };
}
