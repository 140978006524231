import React, {
  ForwardedRef,
  HTMLProps,
  MutableRefObject,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from "react";
import classNames from "classnames";
import { Text } from "../Text";
import PhoneInput from "react-phone-number-input/input";

type Type = "text" | "number";

type Variant = "primary" | "secondary";

const VARIANT_CLASSES: Record<Variant, string> = {
  primary: "rounded-full bg-input text-75 font-medium",
  secondary: "rounded bg-white text-100 font-normal",
};
interface TextFieldPropsBase
  extends Omit<HTMLProps<HTMLInputElement>, "onChange" | "type"> {
  label?: string;
  placeholder?: string;
  className?: string;
  adornment?: string | React.ReactNode;
  variant?: Variant;
  hasError?: boolean;
  onSubmit?: () => void;
}

interface TextFieldPropsText extends TextFieldPropsBase {
  type?: "text" | "tel";
  value?: string;
  onChange: (value: string | undefined) => void;
}

interface TextFieldPropsNumber extends TextFieldPropsBase {
  type: "number";
  value?: number | "";
  onChange: (value: number | undefined) => void;
}

type TextFieldProps = TextFieldPropsText | TextFieldPropsNumber;

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      label,
      placeholder,
      value,
      onChange,
      className = "",
      onSubmit,
      type,
      adornment,
      variant = "primary",
      hasError = false,
      autoComplete = "off",
      ...props
    },
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const [numericEmptyOverride, setNumericEmptyOverride] = useState(false);
    // Define a default for the type prop in the destructuring assignment
    const handleChange = (val: string) => {
      /**
       * If this is a numeric input, we never want to call `onChange` with a non-numeric value. However, we still want the user
       * to be able to erase the value in the input. In this case, we should remember that the input has been emptied in local state,
       * rather than calling `onChange` with a null value.
       */
      if (type === "number") {
        if (val === "") {
          setNumericEmptyOverride(true);
          onChange(undefined);
        } else {
          setNumericEmptyOverride(false);
          onChange(parseFloat(val));
        }
      } else {
        onChange(val);
      }
    };

    useEffect(() => {
      // Define the event handler inside useEffect
      const handleWheel = (event: WheelEvent) => {
        const activeElement = document.activeElement;

        if (
          activeElement &&
          activeElement instanceof HTMLElement &&
          activeElement.tagName === "INPUT" &&
          (activeElement as HTMLInputElement).type === "number"
        ) {
          activeElement.blur();
        }
      };

      // Add the event listener
      document.addEventListener("wheel", handleWheel);

      // Return a cleanup function that removes the event listener
      return () => {
        document.removeEventListener("wheel", handleWheel);
      };
    }, []);

    const handleKeyDown = (event: React.KeyboardEvent) => {
      if (event.key === "Enter" && onSubmit) {
        onSubmit();
      }
    };

    return (
      <div className={classNames("relative", className)}>
        {type === "tel" ? (
          <PhoneInput
            country="US"
            className={classNames(
              "placeholder-subdued h-full w-full border py-200 pl-400 leading-100 focus:bg-input-focus",
              VARIANT_CLASSES[variant],
              adornment ? "pr-1000" : "pr-400",
              hasError && "border-critical",
            )}
            type={type} // Use the validated inputType
            placeholder={placeholder || "(000) 000 0000"}
            value={numericEmptyOverride ? "" : (value as any)} // Use 'any' to bypass the type check for the input's value attribute
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            {...props}
            ref={ref}
            aria-label={label}
          />
        ) : (
          <input
            className={classNames(
              "placeholder-subdued h-full w-full border py-200 pl-400 leading-100 focus:bg-input-focus md:text-100",
              VARIANT_CLASSES[variant],
              adornment ? "pr-1000" : "pr-400",
              hasError && "border-critical",
            )}
            type={type} // Use the validated inputType
            placeholder={placeholder}
            value={numericEmptyOverride ? "" : (value as any)} // Use 'any' to bypass the type check for the input's value attribute
            onChange={(e) => handleChange(e.target.value)}
            onKeyDown={handleKeyDown}
            {...props}
            ref={ref}
            aria-label={label}
          />
        )}
        {adornment && typeof adornment === "string" ? (
          <Text
            color="subdued"
            className="absolute right-400 top-1/2 -translate-y-1/2"
          >
            {adornment}
          </Text>
        ) : (
          <div className="absolute right-400 top-1/2 -translate-y-1/2">
            {adornment}
          </div>
        )}
      </div>
    );
  },
);
