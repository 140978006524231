import {
  KeyboardEventHandler,
  RefObject,
  createContext,
  useContext,
} from "react";

interface SelectContextState {
  isPopoverVisible: boolean;
  setIsPopoverVisible: (isPopoverVisible: boolean) => void;
  handleKeyDown: KeyboardEventHandler<HTMLButtonElement>;
  activatorRef: RefObject<HTMLButtonElement>;
}

const SelectContext = createContext<SelectContextState>({} as any);

export interface SelectContextProviderProps {
  value: SelectContextState;
  children: React.ReactNode;
}

export const SelectContextProvider = ({
  value,
  children,
}: SelectContextProviderProps) => {
  return (
    <SelectContext.Provider value={value}>{children}</SelectContext.Provider>
  );
};

export const useSelectContext = () => useContext(SelectContext);
