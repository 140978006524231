import { ShippingAddress } from "@/apicodegen/api";
import { OreganoText, OreganoTextField } from "@/components/foundation";
import {
  Control,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
  useForm,
} from "react-hook-form";
import { useOrganizationContext } from "@/context/OrganizationContext";
import { HookFormTextField } from "@/components/foundation/HookFormTextField/HookFormTextField";
import { NoSsrAddressOneSuggestionHookFormTextField } from "../NoSsrAddressOneSuggestionHookFormTextField";

export interface EditFromOwnAddressFormData {
  address1: string;
  address2: string | undefined;
  city: string;
  state: string;
  zip: string;
  instructions: string | undefined;
}

export interface EditFromOwnAddressFormProps {
  watch: UseFormWatch<EditFromOwnAddressFormData>;
  control: Control<EditFromOwnAddressFormData, any>;
  register: UseFormRegister<EditFromOwnAddressFormData>;
  setValue: UseFormSetValue<EditFromOwnAddressFormData>;
  errors: FieldErrors<EditFromOwnAddressFormData>;
}

export const EditFromOwnAddressForm = ({
  watch,
  control,
  register,
  setValue,
  errors,
}: EditFromOwnAddressFormProps) => {
  return (
    <form
      className="flex flex-col gap-400"
      onSubmit={(e) => e.preventDefault()}
    >
      <div className="grid grid-cols-1 gap-x-200 gap-y-400 md:grid-cols-2">
        <label className="flex flex-1 flex-col gap-100">
          <OreganoText>Address 1</OreganoText>
          <NoSsrAddressOneSuggestionHookFormTextField
            control={control}
            value={watch("address1")}
            placeholder="Address"
            nearAtlanta
            {...register("address1", { required: true })}
            onChange={(addressSuggestion: Partial<ShippingAddress>) => {
              setValue("address1", addressSuggestion.address1 || "", {
                shouldValidate: true,
              });
              if (addressSuggestion.address2 != null) {
                setValue("address2", addressSuggestion.address2, {
                  shouldValidate: true,
                });
              }
              if (addressSuggestion.city != null) {
                setValue("city", addressSuggestion.city, {
                  shouldValidate: true,
                });
              }
              if (addressSuggestion.state != null) {
                setValue("state", addressSuggestion.state, {
                  shouldValidate: true,
                });
              }
              if (addressSuggestion.zip != null) {
                setValue("zip", addressSuggestion.zip, {
                  shouldValidate: true,
                });
              }
            }}
            hasError={errors.address1 != null}
          />
        </label>
        <label className="flex flex-1 flex-col gap-100">
          <OreganoText>Address 2 (optional)</OreganoText>
          <HookFormTextField
            control={control}
            {...register("address2")}
            placeholder="Apartment, suite, etc."
            variant="secondary"
            hasError={errors.address2 != null}
          />
        </label>
        <label className="flex flex-1 flex-col gap-100">
          <OreganoText>City</OreganoText>
          <HookFormTextField
            control={control}
            {...register("city", { required: true })}
            placeholder="City"
            variant="secondary"
            hasError={errors.city != null}
          />
        </label>
        <label className="flex flex-1 flex-col gap-100">
          <OreganoText>State</OreganoText>
          <HookFormTextField
            control={control}
            {...register("state", { required: true })}
            placeholder="State"
            variant="secondary"
            hasError={errors.state != null}
          />
        </label>
        <label className="flex flex-1 flex-col gap-100">
          <OreganoText>ZIP code</OreganoText>
          <HookFormTextField
            control={control}
            {...register("zip", { required: true })}
            placeholder="Zip"
            variant="secondary"
            hasError={errors.zip != null}
          />
        </label>

        <label className="col-span-1 flex flex-1 flex-col gap-100 md:col-span-2">
          <OreganoText>Pick up instructions (optional)</OreganoText>
          <textarea
            {...register("instructions")}
            spellCheck
            className="placeholder-subdued h-full w-full resize-none rounded border bg-white py-200 pl-400 text-100 font-normal leading-100 focus:bg-input-focus"
            rows={3}
            placeholder="Pickup at front door, buzzer code 1234"
          />
        </label>
      </div>
    </form>
  );
};
