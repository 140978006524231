import classNames from "classnames";
import React, { ChangeEvent, HTMLProps } from "react";

interface CheckboxProps
  extends Omit<HTMLProps<HTMLInputElement>, "type" | "onChange"> {
  checked: boolean;
  onChange: (checked: boolean) => void;
}

export const Checkbox = ({
  checked,
  onChange,
  className,
  ...props
}: CheckboxProps) => {
  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.checked);
  };

  return (
    <input
      {...props}
      type="checkbox"
      checked={checked}
      onChange={onInputChange}
      className={classNames(
        "h-4 w-4 rounded border accent-corrugated-800",
        className,
      )}
    />
  );
};
