import classNames from "classnames";

type Element = "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p" | "span";

export type TextVariant =
  | "bodySm"
  | "bodyMd"
  | "bodyLg"
  | "bodyXl"
  | "body2xl"
  | "subheadingXs"
  | "subheadingSm"
  | "subheadingMd"
  | "subheadingLg"
  | "headingXs"
  | "headingSm"
  | "headingMd"
  | "headingLg"
  | "headingXl"
  | "heading2xl"
  | "heading3xl"
  | "heading4xl";

type Font = "sans" | "serif" | "mono";

const VARIANT_CLASSES: Record<TextVariant, string> = {
  bodySm: "text-75 leading-100 font-normal",
  bodyMd: "text-100 leading-100 font-normal",
  bodyLg: "text-200 leading-200 font-normal",
  bodyXl: "text-300 leading-300 font-normal",
  body2xl: "text-400 leading-400 font-normal",
  subheadingXs: "text-75 leading-100 font-medium",
  subheadingSm: "text-100 leading-100 font-medium",
  subheadingMd: "text-200 leading-200 font-medium",
  subheadingLg: "text-300 leading-300 font-medium",
  headingXs: "text-75 leading-100 font-semibold",
  headingSm: "text-100 leading-100 font-semibold",
  headingMd: "text-200 leading-200 font-semibold",
  headingLg: "text-300 leading-300 font-semibold",
  headingXl: "text-400 leading-400 font-semibold",
  heading2xl: "text-500 leading-500 font-semibold",
  heading3xl: "text-600 leading-600 font-semibold",
  heading4xl: "text-700 leading-700 font-semibold",
};

const FONT_CLASSES: Record<Font, string> = {
  sans: "font-sans",
  serif: "font-serif",
  mono: "font-mono",
};

type Color =
  | "default"
  | "critical"
  | "criticalOnSurface"
  | "disabled"
  | "info"
  | "infoOnSurface"
  | "placeholder"
  | "subdued"
  | "success"
  | "successOnSurface"
  | "warning"
  | "warningOnSurface"
  | "accent"
  | "light"
  | "lightSubdued"
  | "lightAccent";

const COLOR_CLASSES: Record<Color, string> = {
  default: "text",
  critical: "text-critical",
  criticalOnSurface: "text-critical-onSurface",
  info: "text-info",
  infoOnSurface: "text-info-onSurface",
  subdued: "text-subdued",
  disabled: "text-disabled",
  placeholder: "text-placeholder",
  success: "text-success",
  successOnSurface: "text-success-onSurface",
  warning: "text-warning",
  warningOnSurface: "text-warning-onSurface",
  accent: "text-accent",
  light: "text-light",
  lightSubdued: "text-light-subdued",
  lightAccent: "text-light-accent",
};

export interface TextProps extends React.PropsWithChildren {
  as?: Element;
  className?: string;
  color?: Color;
  font?: Font;
  underline?: boolean;
  uppercase?: boolean;
  strikethrough?: boolean;
  italic?: boolean;
  variant?: TextVariant;
}

export const Text = ({
  as = "p",
  children,
  className,
  color = "default",
  variant = "bodyMd",
  font = "sans",
  underline = false,
  uppercase = false,
  strikethrough = false,
  italic = false,
}: TextProps) => {
  const Component = as;

  return (
    <Component
      className={classNames(
        COLOR_CLASSES[color],
        FONT_CLASSES[font],
        VARIANT_CLASSES[variant],
        underline && "underline",
        uppercase && "uppercase",
        strikethrough && "line-through",
        italic && "italic",
        className,
      )}
    >
      {children}
    </Component>
  );
};
