import { PropsWithChildren, ReactNode } from "react";
import { OreganoIcon, OreganoText } from "..";

interface SectionProps extends PropsWithChildren {
  id: string;
  title: string;
  description?: string;
  icon?: ReactNode;
}

export const Section = ({
  id,
  title,
  description,
  children,
  icon,
}: SectionProps) => {
  return (
    <div className="flex w-full flex-col gap-400" id={id}>
      <div className="flex flex-col gap-200">
        <div className="flex flex-row gap-200">
          {icon && <OreganoIcon fill="active">{icon}</OreganoIcon>}
          <OreganoText variant="headingLg">{title}</OreganoText>
        </div>
        {description && <OreganoText>{description}</OreganoText>}
      </div>
      {children}
    </div>
  );
};
