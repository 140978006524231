import { SaltboxLocation } from "@/apicodegen/api";
import {
  OreganoText,
  OreganoPopover,
  OreganoIcon,
  OreganoActionList,
  OreganoActionListButton,
} from "@/components/foundation";
import { ChevronUpIcon, ChevronDownIcon } from "@/public/icons/solid";
import { Dispatch, SetStateAction, useState } from "react";

export interface ShipFromSaltboxLocationFormProps {
  selectedSaltboxLocation: SaltboxLocation;
  saltboxLocations: SaltboxLocation[];
  setSelectedSaltboxLocation: Dispatch<SetStateAction<SaltboxLocation>>;
}

export const ShipFromSaltboxLocationForm = ({
  selectedSaltboxLocation,
  saltboxLocations,
  setSelectedSaltboxLocation,
}: ShipFromSaltboxLocationFormProps) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleSelectChange = (saltboxLocation: SaltboxLocation) => {
    setSelectedSaltboxLocation(saltboxLocation);
    setIsVisible(false);
  };

  return (
    <div className="flex flex-col gap-400">
      <OreganoText as="h3" variant="headingMd">
        Select location
      </OreganoText>

      <div className="flex flex-1 flex-col gap-100">
        <OreganoText>Location</OreganoText>
        <OreganoPopover
          activator={
            <div className="flex h-full flex-row items-center justify-between rounded border bg-white py-200 pl-400 pr-500 text-left">
              <div className="flex flex-col">
                <OreganoText variant="bodyLg">
                  {selectedSaltboxLocation.name}
                </OreganoText>
                <OreganoText variant="bodySm" color="subdued">
                  {`${selectedSaltboxLocation.address.address1}, ${selectedSaltboxLocation.address.city}, ${selectedSaltboxLocation.address.state}`}
                </OreganoText>
              </div>
              <OreganoIcon size="xs" fill="subdued">
                {isVisible ? <ChevronUpIcon /> : <ChevronDownIcon />}
              </OreganoIcon>
            </div>
          }
          isVisible={isVisible}
          setIsVisible={setIsVisible}
        >
          <OreganoActionList className="max-h-[30vh]">
            {saltboxLocations.map((location) => (
              <OreganoActionListButton
                onClick={() => handleSelectChange(location)}
                key={location.id}
                active={selectedSaltboxLocation.id === location.id}
              >
                <OreganoText variant="bodyLg">{location.name}</OreganoText>
                <OreganoText variant="bodySm" color="subdued">
                  {`${location.address.address1}, ${location.address.city}, ${location.address.state}`}
                </OreganoText>
              </OreganoActionListButton>
            ))}
          </OreganoActionList>
        </OreganoPopover>
      </div>
    </div>
  );
};
