import "@/styles/globals.css";
import type { NextPage } from "next";
import type { AppProps } from "next/app";
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import { Inter } from "next/font/google";
import Head from "next/head";
import { Toaster } from "react-hot-toast";
import { LicenseInfo } from "@mui/x-license-pro";
import Script from "next/script";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { RecoilRoot } from "recoil";
import { Frame } from "@/components/foundation/Frame";
import { PopoverPortalContextProvider } from "@/context/PopoverContext";
import { StickyFooterPortalContextProvider } from "@/context/StickyFooterContext";
import { LayoutProvider } from "@/context/LayoutContext";
import { ModalPortalContextProvider } from "@/context/ModalContext";
import { OrganizationProvider } from "@/context/OrganizationContext";
import { ClerkProvider } from "@clerk/nextjs";
import FullStorySnippet from "@/components/utils/FullStorySnippet";
import { enUS } from "@clerk/localizations";
import MouseflowSnippet from "@/components/utils/MouseflowSnippet";
import VersionRefresher from "@/components/utils/useVersionRefresher";
import useVersionRefresher from "@/components/utils/useVersionRefresher";
import { IntercomWrapper } from "@/components/utils/IntercomWrapper";

LicenseInfo.setLicenseKey(
  "0ae9d95781df37a220ed6456b3d78812Tz05MjY3OCxFPTE3NTAyNzc1MjcwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=",
);

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: React.ReactElement) => React.ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const inter = Inter({ subsets: ["latin"], variable: "--font-inter" });

const muiTheme = createTheme({
  typography: {
    fontFamily: inter.style.fontFamily,
  },
});

const signInText =
  "Continue to Parsel to get on-demand, doorstep pickups and exclusive access to e-commerce carriers.";
const signUpText = "By signing up, you agree to Parsel's terms of service.";
export default function App({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout = Component.getLayout || ((page) => page);
  const localization = Object.assign({}, enUS); // just copies the object
  localization!.signIn!.start!.subtitle = signInText;
  localization!.signUp!.start!.subtitle = signUpText;

  const mapsApiKey = process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY;

  useVersionRefresher();
  return (
    <RecoilRoot>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ClerkProvider
          {...pageProps}
          localization={localization}
          appearance={{
            layout: {
              privacyPageUrl: "/privacy-policy",
              termsPageUrl: "/terms-of-service",
            },
          }}
        >
          <ThemeProvider theme={muiTheme}>
            <Head>
              <title>Parsel</title>
            </Head>
            <FullStorySnippet />
            <MouseflowSnippet />
            <CssBaseline />
            <Script
              src={`https://maps.googleapis.com/maps/api/js?libraries=places&key=${mapsApiKey}`}
            />
            <LayoutProvider>
              <ModalPortalContextProvider>
                <PopoverPortalContextProvider>
                  <OrganizationProvider>
                    <IntercomWrapper>
                      <Frame>
                        <StickyFooterPortalContextProvider>
                          <div className={inter.className}>
                            {getLayout(<Component {...pageProps} />)}
                            <Toaster />
                          </div>
                        </StickyFooterPortalContextProvider>
                      </Frame>
                    </IntercomWrapper>
                  </OrganizationProvider>
                </PopoverPortalContextProvider>
              </ModalPortalContextProvider>
            </LayoutProvider>
          </ThemeProvider>
        </ClerkProvider>
      </LocalizationProvider>
    </RecoilRoot>
  );
}
