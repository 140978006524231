const ONBOARDING_BASE_ROUTE = "/onboarding";

export const onboardingRoutes = {
  onboardingContact: `${ONBOARDING_BASE_ROUTE}/profile/contact`,
  onboardingAddress: `${ONBOARDING_BASE_ROUTE}/profile/address`,
  onboardingShopifySync: `${ONBOARDING_BASE_ROUTE}/shopify`,
  onboardingProfile: `${ONBOARDING_BASE_ROUTE}/profile`,
};

const SETTINGS_BASE_ROUTE = "/settings";

const settingRoutes = {
  settings: SETTINGS_BASE_ROUTE,
  settingsOrganization: `${SETTINGS_BASE_ROUTE}/organization`,
  settingsUsers: `${SETTINGS_BASE_ROUTE}/users`,
  settingsStorefronts: `${SETTINGS_BASE_ROUTE}/storefronts`,
  settingsPackages: `${SETTINGS_BASE_ROUTE}/packages`,
  settingsPackingSlip: `${SETTINGS_BASE_ROUTE}/packing-slip`,
  settingsServiceLevels: `${SETTINGS_BASE_ROUTE}/service-levels`,
};

export const staticRoutes = {
  home: "/",
  orders: "/orders",
  pickups: "/pickups",
  storefronts: "/storefronts",
  // ship: "/ship",
  batch: "/batch",
  shipments: "/shipments",
  onboarding: onboardingRoutes,
  scan: "/scan",
  comingSoon: "/coming-soon",
  ship: "/",
};

export const rawRoutes = {
  privacyPolicy: "/privacy-policy",
  termsOfService: "/terms-of-service",
  health: "/health",
  signIn: "/sign-in/[[...index]]",
  signUp: "/sign-up/[[...index]]",
  logOut: "/logout",
  shopifySyncMiddleware: "/shopify/sync-middleware",
  costComparisonWidget: "/widgets/cost-comparison",
  oauthCallback: "/auth/oauth-callback",
  emailLinkVerification: "/auth/email-link-verification",
};

export const routes = {
  ...staticRoutes,
  ...onboardingRoutes,
  ...rawRoutes,
  ...settingRoutes,
};

export const OPERATOR_ROUTES = [
  routes.scan,
  routes.settings,
  routes.settingsUsers,
];
