import { useOrganizationContext } from "@/context/OrganizationContext";
import useLocalStorage from "@/hooks/useLocalStorage";

export const useSkippedOnboardingShopifySyncLocalStorage = (
  orgId: string | undefined,
) => {
  const {
    value: skippedOnboardingShopifySync,
    updateValue: setSkippedOnboardingShopifySync,
    getValue: getSkippedOnboardingShopifySync,
  } = useLocalStorage<boolean>(
    `${orgId}_SKIPPED_ONBOARDING_SHOPIFY_SYNC`,
    false,
  );

  return {
    skippedOnboardingShopifySync,
    setSkippedOnboardingShopifySync,
    getSkippedOnboardingShopifySync,
  };
};
