import { PropsWithChildren, useEffect, useRef, useState } from "react";
import { useCreateModal } from "@/context/ModalContext";
import classNames from "classnames";
import { CSSTransition } from "react-transition-group";

type Size = "small" | "default" | "half";

const MODAL_SIZE_CLASSES: Record<Size, string> = {
  small:
    "w-[calc(100vw-32px)] *:w-full *:h-full md:*:h-auto md:h-initial md:max-h-full md:w-[800px]",
  default:
    "w-full h-full *:w-full *:h-full md:*:h-auto md:h-initial md:max-h-full md:w-[800px] max-md:*:rounded-none",
  half: "w-full h-full md:w-1/2 md:h-auto max-md:*:rounded-none",
};

interface ModalProps extends PropsWithChildren {
  size?: Size;
  showModal: boolean;
}

export const Modal = ({
  children,
  size = "default",
  showModal,
}: ModalProps) => {
  const [showModalLocal, setShowModalLocal] = useState(showModal);
  const { createModal, setHasActiveModal } = useCreateModal();
  const nodeRef = useRef(null);

  // Update local showModal state when prop changes
  useEffect(() => {
    if (showModal) {
      setShowModalLocal(showModal);
      setHasActiveModal(true);
    } else {
      setTimeout(() => {
        setShowModalLocal(false);
        setHasActiveModal(false);
      }, 300);
    }
  }, [showModal, setHasActiveModal]);

  useEffect(() => {
    return () => setHasActiveModal(false);
  }, []);

  return showModalLocal ? (
    <>
      {createModal(
        <CSSTransition
          nodeRef={nodeRef}
          in={showModal}
          timeout={300}
          classNames={{
            appear: "opacity-0",
            appearActive: "opacity-100",
            appearDone: "opacity-100",
            enter: "opacity-0",
            enterActive: "opacity-100",
            enterDone: "opacity-100",
            exit: "opacity-0",
            exitActive: "opacity-0",
            exitDone: "opacity-0",
          }}
          unmountOnExit
          appear
        >
          <div
            ref={nodeRef}
            className={classNames(
              "fixed left-0 top-0 z-modal flex h-svh w-full bg-transparentOverlayBackground transition-opacity",
              showModal && "opacity-0",
              size === "half"
                ? "justify-end"
                : "lg:p-1400 items-center justify-center md:p-1000",
            )}
          >
            <div
              className={classNames(
                "flex max-h-full max-w-full items-center justify-center *:max-h-full *:w-full",
                MODAL_SIZE_CLASSES[size],
              )}
            >
              {children}
            </div>
          </div>
        </CSSTransition>,
      )}
    </>
  ) : null;
};
