import { useCreateStickyFooter } from "@/context/StickyFooterContext";
import classNames from "classnames";
import { HTMLProps } from "react";
import { PageLayout } from "../PageLayout";
import { useLayoutContext } from "@/context/LayoutContext";
import {
  HideWhenScreenIs,
  type HideWhenScreenIsProps,
} from "../HideWhenScreenIs";

interface StickyFooterProps extends HTMLProps<HTMLDivElement> {
  hideWhen?: HideWhenScreenIsProps;
  childrenWrapperClassName?: string;
  scrollVariant?: "white" | "corrugated";
}

export const StickyFooter = ({
  children,
  className,
  hideWhen,
  childrenWrapperClassName,
  scrollVariant = "white",
}: StickyFooterProps) => {
  const { createStickyFooter } = useCreateStickyFooter();
  const { hideSidebar, isScrollable } = useLayoutContext();

  return createStickyFooter(
    <div
      className={classNames(
        "fixed bottom-0 z-stickyFooter w-full",
        !hideSidebar && "md:w-[calc(100vw-64px)]",
        // this is currently set up to align the button with page content
        isScrollable && scrollVariant === "white" && "stickyFooter",
        isScrollable &&
          scrollVariant === "corrugated" &&
          "stickyFooterCorrugated",
        className,
      )}
    >
      <HideWhenScreenIs {...hideWhen}>
        <PageLayout
          className={classNames(
            "border-t border-dark py-300",
            childrenWrapperClassName,
          )}
        >
          {children}
        </PageLayout>
      </HideWhenScreenIs>
    </div>,
  );
};
