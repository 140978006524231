import {
  OreganoButton,
  OreganoIcon,
  OreganoTag,
  OreganoText,
} from "@/components/foundation";
import { XmarkIcon } from "@/public/icons/solid";

interface DismissibleTagProps extends React.PropsWithChildren {
  onRemoveClick: () => void;
}

export const DismissibleTag = ({
  children,
  onRemoveClick,
}: DismissibleTagProps) => {
  return (
    <OreganoTag
      variant="subdued"
      className="flex flex-row items-center gap-2xs pl-100"
      padding="none"
    >
      <OreganoText variant="subheadingXs" className="pl-100">
        {children}
      </OreganoText>
      <OreganoButton
        shape="none"
        className="aspect-square rounded-r-sm p-100"
        variant="icon"
        onClick={() => onRemoveClick()}
      >
        <OreganoIcon size="sm">
          <XmarkIcon />
        </OreganoIcon>
      </OreganoButton>
    </OreganoTag>
  );
};
