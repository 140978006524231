import { HTMLProps, ReactNode } from "react";
import classNames from "classnames";

type Color =
  | "dark"
  | "default"
  | "light"
  | "info"
  | "critical"
  | "warning"
  | "success"
  | "transparent";

interface DividerProps extends HTMLProps<HTMLHRElement> {
  color?: Color;
}

const COLOR_CLASSES: Record<Color, string> = {
  dark: "border-dark",
  default: "",
  light: "border-light",
  info: "border-info",
  critical: "border-critical",
  warning: "border-warning",
  success: "border-success",
  transparent: "border-transparent",
};

export function Divider({ className, color = "default" }: DividerProps) {
  return <hr className={classNames(COLOR_CLASSES[color], className)} />;
}
