import { Avatar } from "./Avatar";
import { Card } from "../../Card";
import { CSSTransition } from "react-transition-group";
import { OrganizationSwitcher } from "./OrganizationSwitcher";
import { Text } from "../../Text";
import { useGetOrganization } from "@/hooks/api/organization/useGetOrganization";
import { useState, useRef, useEffect, useCallback } from "react";
import classNames from "classnames";
import { HideWhenScreenIs } from "../../HideWhenScreenIs";
import { useClerk } from "@clerk/nextjs";
import { useRouter } from "next/router";
import { mutate, useSWRConfig } from "swr";
import { routes } from "@/routes";

export const TopBarOrgNav = () => {
  const [popoverVisible, setPopoverVisible] = useState(false);
  const { data: activeOrganization, isLoading: activeOrganizationLoading } =
    useGetOrganization();

  const componentRef = useRef<HTMLButtonElement | null>(null);
  const popoverRef = useRef<HTMLDivElement | null>(null);

  const router = useRouter();

  useEffect(() => {
    // Adds event listener to close popover when clicking outside of it
    const handleClickOutside = (event: MouseEvent) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target as Node) &&
        popoverRef.current &&
        !popoverRef.current.contains(event.target as Node)
      ) {
        setPopoverVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative h-full max-w-[20vw] select-none">
      <button
        onClick={() => setPopoverVisible(!popoverVisible)}
        className="flex h-full w-full cursor-default flex-row items-center gap-100 overflow-hidden whitespace-nowrap
        rounded bg-dark-interactive p-200 transition hover:bg-dark-interactive-hover"
        ref={componentRef}
      >
        {(activeOrganization?.name || activeOrganizationLoading) && (
          <Avatar name={activeOrganization?.name || ""} />
        )}
        <HideWhenScreenIs smallest xs sm className="w-full overflow-hidden">
          <Text className="w-full overflow-hidden text-ellipsis text-light">
            {activeOrganization?.name || "----"}
          </Text>
        </HideWhenScreenIs>
      </button>
      <CSSTransition
        in={popoverVisible}
        nodeRef={popoverRef}
        timeout={300}
        classNames={{
          appearActive: "translate-y-100",
          appearDone: "translate-y-100",
          enterActive: "translate-y-100",
          enterDone: "translate-y-100",
          exit: "opacity-0",
        }}
        unmountOnExit
      >
        <div
          className={classNames(
            "absolute right-0 top-full mt-100 w-64 max-w-[100vw] transition-transform",
          )}
          ref={popoverRef}
        >
          <Card className="overflow-hidden shadow">
            <OrganizationSwitcher />
            <div className="p-200">
              <button
                className="flex w-full flex-row rounded p-200 transition hover:bg-card-hover"
                onClick={() => router.push(routes.logOut)}
              >
                <Text>Logout</Text>
              </button>
            </div>
          </Card>
        </div>
      </CSSTransition>
    </div>
  );
};
