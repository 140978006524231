import {
  ReactNode,
  RefObject,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { createPortal } from "react-dom";
import { useLayoutContext } from "./LayoutContext";

interface ModalPortalContextState {
  containerRef: RefObject<HTMLDivElement> | null;
  hasActiveModal: boolean;
  setHasActiveModal: (arg: boolean) => void;
}

const ModalPortalContext = createContext<ModalPortalContextState>({
  containerRef: null,
  hasActiveModal: false,
  setHasActiveModal: (_arg: boolean) => {},
});

interface ModalPortalContextProviderProps {
  children: React.ReactNode;
}

export const ModalPortalContextProvider = ({
  children,
}: ModalPortalContextProviderProps) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [hasActiveModal, setHasActiveModal] = useState(false);
  const { isScrollable, scrollbarWidth, setPreventUserScroll } =
    useLayoutContext();

  useEffect(() => {
    if (hasActiveModal) {
      document.body.style.overflow = "hidden"; // Disable scrolling
      document.body.style.maxWidth = isScrollable
        ? `calc(100vw - ${scrollbarWidth}px)`
        : "";
      setPreventUserScroll(true);
    } else {
      document.body.style.overflow = "unset"; // Enable scrolling
      document.body.style.paddingRight = "0";
      setPreventUserScroll(false);
    }

    // Cleanup function to ensure scrolling is enabled when the component unmounts
    return () => {
      document.body.style.overflow = "unset";
      setPreventUserScroll(false);
    };
  }, [hasActiveModal, isScrollable, scrollbarWidth]);

  return (
    <ModalPortalContext.Provider
      value={{ containerRef, hasActiveModal, setHasActiveModal }}
    >
      <div>
        {children}
        <div id="modal_portal" ref={containerRef}></div>
      </div>
    </ModalPortalContext.Provider>
  );
};

export const useModalPortalContext = () => {
  return useContext(ModalPortalContext);
};

export const useCreateModal = () => {
  const { containerRef, hasActiveModal, setHasActiveModal } =
    useModalPortalContext(); // Get the portal container from context

  const createModal = (children: ReactNode) => {
    if (containerRef?.current) {
      return createPortal(children, containerRef.current);
    }
  };

  return {
    createModal,
    hasActiveModal,
    setHasActiveModal,
  };
};
